import Vue from 'vue'
import Router from 'vue-router'
import Home from './pages/home'
import Index from './pages/index'
const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router);

export default new Router({

	routes: [{
			path: '/',
			name: 'home',
			component: Home,
			redirect: '/index',
			children: [{
					path: '/index', //首页
					name: 'index',
					component: Index,
				},
				{
					path: '/hangar/:id?', //飞机库
					name: 'hangar',
					component: () => import('./pages/Hangar.vue')
				},
				{
					path: '/search/:id?', //搜索结果页
					name: 'search',
					component: () => import('./pages/search.vue')
				},
				{
					path: '/comparison', //对比页
					name: 'comparison',
					component: () => import('./pages/comparison.vue')
				},
				{
					path: '/airplaneDetail', //飞机详情
					name: 'airplaneDetail',
					component: () => import('./pages/airplaneDetail.vue')
				},
				{
					path: '/aircraftBrand', //飞机品牌
					name: 'aircraftBrand',
					component: () => import('./pages/aircraftBrand.vue')
				}, {
					path: '/aircraftBrandDetail', //飞机品牌详情
					name: 'aircraftBrandDetail',
					component: () => import('./pages/aircraftBrandDetail.vue')
				},
				{
					path: '/customerService/:id?', //客户服务
					name: 'customerService',
					component: () => import('./pages/customerService.vue')
				},
				{
					path: '/industryNews', //行业新闻
					name: 'industryNews',
					component: () => import('./pages/industryNews.vue')
				},
				{
					path: '/industryNewsDetail', //行业新闻详情
					name: 'industryNewsDetail',
					component: () => import('./pages/industryNewsDetail.vue')
				},
				{
					path: '/industryCustomers', //行业客户
					name: 'industryCustomers',
					component: () => import('./pages/industryCustomers.vue')
				},
				{
					path: '/contactUs/:id?', //联系我们
					name: 'contactUs',
					component: () => import('./pages/contactUs.vue')
				},
				{
					path: '/companyProfile', //公司简介
					name: 'companyProfile',
					component: () => import('./pages/companyProfile.vue')
				},
				{
					path: '/findPlane', //找飞机
					name: 'findPlane',
					component: () => import('./pages/findPlane.vue')
				},
				{
					path: '/userinfo',
					name: 'userinfo',
					component: () => import('./pages/userInfo.vue')
				},
				{
					path: '/trading', //交易进展 
					name: 'trading',
					component: () => import('./pages/trading.vue')
				},
				{
					path: '/report', //月报
					name: 'report',
					component: () => import('./pages/report.vue')
				}
			]
		},
		{
			path: '/login', //登录
			name: 'login',
			component: () => import('./pages/login.vue')
		},
		{
			path: '/registered', //注册
			name: 'registered',
			component: () => import('./pages/registered.vue')
		},
		{
			path: '/retrievePassword', //找回密码
			name: 'retrievePassword',
			component: () => import('./pages/retrievePassword.vue')
		}, {
			path: '/editPassword', //找回密码
			name: 'editPassword',
			component: () => import('./pages/editPassword.vue')
		}
	],
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		}
	},
});
