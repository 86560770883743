// Vuex-actions
export default {
  setLanguage(context, lang) {
    context.commit('saveLanguage', lang)
  },saveUserInfo(context, username) {
    context.commit('saveUserInfo', username)
  },
  saveUserToken(context, params) {
	context.commit('saveUserToken', params)
  }
}