import http from '../util/http'
import $config from "./config.js"
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = $config.apiUrl

//####### get请求  #######//
//获取交易进展设置接口
// 获取数据
export const airProgress = {
	//买飞机
	airProgressFindplane: function(params) {
		return http.get(`${resquest}/airprogressfindplane/airProgressFindplane/list2`, params)
	},
	//卖飞机
	airProgressSaleplane: function(params) {
		return http.get(`${resquest}/airprogresssaleplane/airProgressSaleplane/list2`, params)
	},
	//飞机托管
	airProgressHostingplane: function(params) {
		return http.get(`${resquest}/airprogresshostingplane/airProgressHostingplane/list2`, params)
	}
}
// export function airProgress(params) {
// 	return http.get(`${resquest}/airprogress/airProgress/list`, params)
// }
//获取品牌类型
export function getAirBrandPavilion(params) {
	return http.get(`${resquest}/airBrandPavilion/airBrandPavilion/list`, params)
}
//获取所有品牌
export function getAirPlaneBrandList(params) {
	return http.get(`${resquest}/airplanebrand/airPlaneBrand/list`, params)
}

//获取个人中心更新信息  红点
export function getUpdateNotifi(params) {
	return http.get(`${resquest}/msgnotification/msgNotification/getUpdateNotifi`, params)
}
//获取飞机详情PDF导出
export function getExportPdf(params) {
	return http.getBlob(`${resquest}/pdf/exportPdf`, params)
}
//获取验证码
export function getCaptcha(checkKey, params) {
	return http.get(`${resquest}/sys/randomImage/${checkKey}`, params)
}
//新闻
export function getNews(params) {
	return http.get(`${resquest}/airnews/airNews/listByPublish`, params)
}
//获取飞机型号
export function getAirPlaneType(params) {
	return http.get(`${resquest}/airplanetype/airPlaneType/list`, params)
}
//根据飞机类型查找飞机品牌
export function getAirPlaneBrand(params) {
	return http.get(`${resquest}/airplanebrand/airPlaneBrand/listByPlaneType`, params)
}
//根据飞机类型、飞机品牌查找飞机
export function getAirInfo(params) {
	return http.post(`${resquest}/airinfo/airInfo/listByFilter`, params)
}
//获取企业文化设置信息
export function getAirComCulture(params) {
	return http.get(`${resquest}/aircomculture/airComCulture/list`, params)
}
//获取网站设置信息
export function getAirComSetting(params) {
	return http.get(`${resquest}/aircomsetting/airComSetting/list`, params)
}
//获取所有的飞机销售类型
export function getAirPlaneSellType(params) {
	return http.get(`${resquest}/airplaneselltype/airPlaneSellType/list`, params)
}
//获取新品飞机 热卖飞机 ID 获取飞机
export function getAirPlaneSellInfo(params) {
	return http.get(`${resquest}/airinfo/airInfo/list`, params)
}

//获取发动机列表
export function getAirEngines(params) {
	return http.get(`${resquest}/airinfo/airInfo/queryAirEnginesByMainId`, params)
}
//根据userid获取飞机托管基础信息
export function getAirPlaneBrandByUser(params) {
	return http.get(`${resquest}/airplanebrand/airPlaneBrand/list`, params)
}
//获取品牌与型号
export function getBrandAndType(params) {
	return http.get(`${resquest}/airplanebrand/airPlaneBrand/getBrandAndType`, params)
}
//飞机库数据
export function getHangars(params) {
	return http.get(`${resquest}/airplaneselltype/airPlaneSellType/listInfo`, params)
}
//根据userid获取飞机托管基础信息
export function getPlaneInfoByUserId(params) {
	return http.get(`${resquest}/airplanebrand/airPlaneBrand/list`, params)
}
//获取专业团队
export function getAirTeamIntroduce(params) {
	return http.get(`${resquest}/airteamintroduce/airTeamIntroduce/list`, params)
}
//获取短信验证码接口(重置密码)
export function getSendVerificationCode(params) {
	return http.get(`${resquest}/sys/sendVerificationCode`, params)
}

//友情链接
export function getAirLinks(params) {
	return http.get(`${resquest}/airlinks/airLinks/list`, params)
}
//首页图片和文章设置查询
export function getAirFrontPageSetting(params) {
	return http.get(`${resquest}/airfrontpagesetting/airFrontPageSetting/getSetting`, params)
}
//董事介绍设置
export function getAirLeaderIntorduce(params) {
	return http.get(`${resquest}/airleaderintorduce/airLeaderIntorduce/listByWeb`, params)
}
//48、获取拜访记录
export function getQueryAirCustomerRecordByMainIdAndFront(params) {
	return http.get(`${resquest}/aircustomer/airCustomer/queryAirCustomerRecordByMainIdAndFront`, params)
}

// 获取数据
export const getAir = {
	//买飞机
	getAirFindInfo: function(params) {
		return http.get(`${resquest}/airfindinfo/airFindInfo/listByFront`, params)
	},
	//卖飞机
	getAirSellInfo: function(params) {
		return http.get(`${resquest}/airsellinfo/airSellInfo/listByFront`, params)
	},
	//飞机托管
	getAirPlaneTrusteeship: function(params) {
		return http.get(`${resquest}/airplanetrusteeship/airPlaneTrusteeship/listByFront`, params)
	}
}

//####### post请求  #######//
//获取品牌列表
export function getListByPlaneType(params) {
	return http.post(`${resquest}/airplanebrand/airPlaneBrand/listByBrand`, params)
}
//添加拜访记录
export function airCustomerAdd(params) {
	return http.post(`${resquest}/aircustomer/airCustomer/addByRecord`, params)
}
//获取当前交易进展
export function frontProgress(params) {
	return http.post(`${resquest}/frontProgress/queryProgress`, params)
}
//获取当前交易进展
export function frontProgress2(params) {
	return http.post(`${resquest}/frontProgress/queryProgress2`, params)
}
//当前交易备注
export function addCommentary(params) {
	return http.post(`${resquest}/frontProgress/addCommentary`, params)
}
//登录
export function login(params) {
	return http.post(`${resquest}/front/login`, params)
}
//留言
export function messageAdd(params) {
	return http.post(`${resquest}/aironlinemessage/airOnlineMessage/add`, params)
}
export const emailEvn = {
	//发送邮箱
	sendCode:function(params){
		return http.post(`${resquest}/front/sendEmail`, params)
	},
	//邮箱注册
	regEmail:function(params){
		return http.post(`${resquest}/front/registerEmail`, params)
	}
}
//注册
export function register(params) {
	return http.post(`${resquest}/front/register`, params)
}
//修改密码
export function passwordChange(params) {
	return http.post(`${resquest}/front/lostPassword`, params)
}
//获取短信验证码接口(注册)
export function sendSms(params) {
	return http.post(`${resquest}/front/sms`, params)
	// return http.post(`${resquest}/sys/sms`, params)
}

//39、根据飞机类型、品牌、座位数、航程、价格搜索飞机
export function listByFilter(params) {
	return http.post(`${resquest}/airsaleplane/airSalePlane/listByFilter`, params)
}
export const customerService = {
	//买飞机
	airFindInfoAdd: function(params) {
		return http.post(`${resquest}/airfindinfo/airFindInfo/add`, params)
	},
	//卖飞机
	airSellInfoAdd: function(params) {
		return http.post(`${resquest}/airsellinfo/airSellInfo/add`, params)
	},
	//飞机托管
	airPlaneTrusteeshipAdd: function(params) {
		return http.post(`${resquest}/airplanetrusteeship/airPlaneTrusteeship/add`, params)
	},
	//工程服务
	airEngineeringService: function(params) {
		return http.post(`${resquest}/airengineeringservice/airEngineeringService/add`, params)
	},
	//工程服务
	airAviationFinancial: function(params) {
		return http.post(`${resquest}/airAviationFinancial/airAviationFinancial/add`, params)
	},
	//加入我们
	airJoinUs: function(params) {
		return http.post(`${resquest}/airJoinUs/airJoinUs/add`, params)
	},
}

//####### put 请求  #######// 
//修改用户
export function editUserInfo(params) {
	return http.put(`${resquest}/sys/user/appEdit`, params)
}
export function putSomeAPI(params) {
	return http.put(`${resquest}/putSome.json`, params)
}
export function putFrontEdit(params) {
	return http.put(`${resquest}/airhostingoperation/airHostingOperation/frontEdit`, params)
}
// 客户表单数据修改
// export function putAirsellinfo(params) {
// 	return http.put(`${resquest}/airsellinfo/airSellInfo/edit`, params)
// }
export const putAir = {
	//买飞机
	putAirFindInfo: function(params) {
		return http.put(`${resquest}/airfindinfo/airFindInfo/edit`, params)
	},
	//卖飞机
	putAirSellInfo: function(params) {
		return http.put(`${resquest}/airsellinfo/airSellInfo/edit`, params)
	},
	//飞机托管
	putAirPlaneTrusteeship: function(params) {
		return http.put(`${resquest}/airplanetrusteeship/airPlaneTrusteeship/edit`, params)
	}
}
//####### delete 请求  #######//  
export function deleteListAPI(params) {
	return http.delete(`${resquest}/deleteList.json`, params)
}
//####### 个人主页  #######//  
export const ownerApi = {
	//机身及发动机
	list30Add: function(params) {
		return http.post(`${resquest}/airhostinginfo/airHostingInfo/addByHostingItemHours`, params)
	},
	
	list30Edit: function(params) {
		return http.post(`${resquest}/airhostinginfo/airHostingInfo/editByHostingItemHoursByList`, params)
	},

	list30Del: function(params) {
		return http.delete(`${resquest}/airhostinginfo/airHostingInfo/deleteAirHostingItemHoursByMainId`, params)
	},
	//行程统计
	list32Add: function(params,headers={}) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/addByAirMoneyRemainingContent`, params,headers)
	},
	
	list32Edit: function(params) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/editByAirHoursCountByList`, params)
	},

	list32Del: function(params) {
		return http.delete(`${resquest}/airhostingoperation/airHostingOperation/editByAirHoursCountByList`, params)
	},
	//行程概要
	list33Add: function(params,headers={}) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/addByAirTourSummary`, params,headers)
	},
	
	list33Edit: function(params) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/editByAirTourSummaryByList`, params)
	},

	list33Del: function(params) {
		return http.delete(`${resquest}/airhostingoperation/airHostingOperation/deleteByAirTourSummary`, params)
	},
	//当月维修
	list34Add: function(params,headers={}) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/addByAirRepairDetail`, params,headers)
	},
	
	list34Edit: function(params) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/editByAirRepairDetailByList`, params)
	},

	list34Del: function(params) {
		return http.delete(`${resquest}/airhostingoperation/airHostingOperation/deleteByAirRepairDetail`, params)
	},
	//AD/SB 执行情况
	list35Add: function(params,headers={}) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/addByAdSbDetail`, params,headers)
	},
	
	list35Edit: function(params) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/editByAdSbDetailByList`, params)
	},

	list35Del: function(params) {
		return http.delete(`${resquest}/airhostingoperation/airHostingOperation/deleteByAdSbDetail`, params)
	},
	//飞机故障/缺陷监
	list36Add: function(params,headers={}) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/addByAirFaultMonitoring`, params,headers)
	},
	
	list36Edit: function(params) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/editByAirFaultMonitoringByList`, params)
	},

	list36Del: function(params) {
		return http.delete(`${resquest}/airhostingoperation/airHostingOperation/deleteByAirFaultMonitoring`, params)
	},
	//账单
	list37Add: function(params,headers={}) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/addByAirMoneyRemainingContent`, params,headers)
	},
	
	list37Edit: function(params) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/editByAirMoneyRemainingContentByList`, params)
	},	

	list37Del: function(params) {
		return http.delete(`${resquest}/airhostingoperation/airHostingOperation/deleteByAirMoneyRemainingContent`, params)
	},
	//航材
	list38Add: function(params,headers={}) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/addByAirMaterialStorage`, params,headers)
	},
	list38Edit: function(params) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/editByAirMaterialStorageByList`, params)
	},
	list38Del: function(params) {
		return http.delete(`${resquest}/airhostingoperation/airHostingOperation/deleteByAirMaterialStorageContent`, params)
	},
	//结构损伤
	list39Add: function(params,headers={}) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/addByAirHostingOperationMonitoring`, params,headers)
	},
	list39Edit: function(params) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/editByAirHostingOperationMonitoringByList`, params)
	},
	list39Del: function(params) {
		return http.delete(`${resquest}/airhostingoperation/airHostingOperation/deleteByAirHostingOperationMonitoring`, params)
	},
	//其它
	list40Add: function(params,headers={}) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/addByAirHostingOperationOthers`, params,headers)
	},
	list40Edit: function(params) {
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/editByAirHostingOperationOthersByList`, params)
	},
	list40Del: function(params) {
		return http.delete(`${resquest}/airhostingoperation/airHostingOperation/deleteByAirHostingOperationOthers`, params)
	}
}
export const ownerComment = {
	add:function(params,headers={}) {
		
		return http.post(`${resquest}/airhostingoperation/airHostingOperation/addByAirOperationComment`, params,headers)
	},
	get:function(params,headers={}) {
		// console.log(headers);
		return http.get(`${resquest}/airhostingoperation/airHostingOperation/queryAirOperationCommentByMainId`, params,headers)
	}
}
