module.exports = {
	// 对象操作
	assign: function(obj, key, val) {
		obj[key] = val;
	},
	removeByKey: function(obj, key) {
		delete obj[key];
	},
	each: function(obj, func) {
		for (let k in obj) {
			func(k, obj[k]);
		}
	},
	isEmptyObj: function(obj) {
		return JSON.stringify(obj) === '{}';
	},
	resetObj:function(obj){
		for (let k in obj) {
			obj[k] = '';
		}
		return obj;
	},
	// --- 数组操作 ---
	// 数组合并
	arrayConcat: function() {
		var tmpArr = [];
		for (let i = 0; i < arguments.length; i++) {
			tmpArr = tmpArr.concat(arguments[i]);
		}
		return tmpArr;
	},
	//删除数组内的指定元素 
	arrayDrop: function(array, index, howmany) {
		if (!index) {
			index = 0;
		}
		if (!howmany) {
			howmany = 1;
		}
		array.splice(index, howmany);
		return array;
	},
	//删除数组内的指定元素 在数组内查找指定元素
	arrayIndexOf: function(arr, needFind) {
		var index = -1;
		for (let i = 0; i < arr.length; i++) {
			if (arr[i] == needFind) {
				index = i;
				return i;
			}
		}
		return index;
	},
	// 比较2个数组的不同的值
	arrayDifference: function(a, b) {
		const set = new Set(b);
		return a.filter(x => !set.has(x));
	},
	//数组随机排序
	arrayShuffle: function(arr) {
		let l = arr.length;
		while (l) {
			const i = Math.floor(Math.random() * l--);
			[arr[l], arr[i]] = [arr[i], arr[l]];
		}
		return arr;
	},
	//对数值型数组求和
	arraySum: function(arr) {
		return arr.reduce((acc, val) => acc + val, 0);
	},
	//对数值型数组进行平均值计算
	arrayAvg: function(arr) {
		return arr.reduce((acc, val) => acc + val, 0) / arr.length;
	},
	//遍历数组
	arrayEach: function(arr, fun) {
		for (let i = 0; i < arr.length; i++) {
			fun(arr[i], i);
		}
	}
}
