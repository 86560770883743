<template>
	<div class="messageBox">
		<div class="msg-title">
			<ul>
				<li>
					<el-select :value="options[currentIndex].label" size="mini" @change="changeSelect" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</li>
				<li>
					<span :class="[current == index ? 'current' : '']" @click.stop="changeTitle(index)" v-for="(item, index) in title" :key="index">{{ item.name }}</span>
				</li>
				<li class="lastLi" >
					<el-date-picker
						v-model="searchDate"
						@change="changeDate"
						value-format="yyyy-MM"
						:default-value="searchDate"
						type="month"
						style="border:none"
						size="mini"
						placeholder="选择日期"
					></el-date-picker>
				</li>
			</ul>
		</div>
		<div class="msg-body">
			<Msg41 v-if="current == 0" :detail29="list29[currentIndex] ? list29[currentIndex] : {}" :table="table30"></Msg41>
			<Msg42
				v-if="current == 1"
				:table32="list32"
				:table33="list33"
				:table34="table34"
				:table35="table35"
				:table36="table36"
				:table37="table37"
				:table38="table38"
				:table39="table39"
				:table40="table40"
				:table41="commentList"
			></Msg42>
		</div>
	</div>
</template>

<script>
// import hangarTitle from '../components/hangarTitle';
// import signMd5Utils from '../../assets/lib/signMd5Utils.js';
import { mapState, mapMutations } from 'vuex';
import {
	ownerComment
} from '../../api/api';
import Msg41 from './msg41.vue';
import Msg42 from './msg42.vue';
export default {
	components: {
		Msg41,
		Msg42
	},
	props: {},
	data() {
		return {
			title: [{ name: this.$t('userInfoDetail.title1') }, { name: this.$t('userInfoDetail.title2') }],
			current: 0,
			value: '',

			table30: [],
			table34: [],
			table37: [],
			table35: [],
			table36: [],
			table38: [],
			table39: [],
			table40: [],
			searchDate: '',
			year: '',
			month: '',
			options: [{id:'',label:'',value:''}],
			detail29_label: '',
			currentIndex: 0,
			currentId: 0,
			detail29: {},
			list29: [],
			list30: [],
			list31: [],
			list32: [],
			list33: [],
			list34: [],
			list35: [],
			list36: [],
			list37: [],
			list38: [],
			list39: [],
			list40: [],
			titleType: 'list33',
			commentList:[]
		};
	},
	created: function() {
		if (this.isLogin == false) {
			this.$router.push({ name: 'index' });
		}

		// console.log(this.userInfo);
		this.year = new Date().getFullYear();
		this.month = new Date().getMonth() + 1;
		this.searchDate = this.year + '-' + this.month;
	},
	computed: {
		...mapState(['userInfo', 'token', 'isLogin']),
		isShowReport() {
			let isShow = this.userInfo.userAuthority.indexOf('hosting');
			return isShow != -1 ? true : false;
		}
	},
	beforeMount: async function() {
		// this.getData();
		await this.planeInfoByUserId();
	},
	methods: {
		...mapMutations(['logOut']),
		async changeSelect(index = 0) {
			this.currentId = this.options[index].id;
			this.currentIndex = index;
			this.detail29_label = this.options[index].label;
			this.detail29 = this.list29[index];
			// console.log(this.currentId);
			//托管机身及发动机小时数（根据 29、托管飞机基本信息ID获取）
			await this.getList30(this.currentId);
			// 31、飞机运营月报基础信息（根据 29、托管飞机基本信息ID,年、月获取）
			await this.getList31(this.currentId, this.year, this.month);
		},
		changeDate: function() {
			let dateArr = this.searchDate.split('-');
			this.year = dateArr[0];
			this.month = dateArr[1];
			// console.log(dateArr);
			// 31、飞机运营月报基础信息（根据 29、托管飞机基本信息ID,年、月获取）
			this.getList31(this.detail29.id, this.year, this.month);
		},
		changeTitle(index = 0) {
			this.current = index;
		},
		setEmpty(){
			this.list30 = [];
			this.list31 = [];
			this.list32 = [];
			this.list33 = [];
			this.list34 = [];
			this.list35 = [];
			this.list36 = [];
			this.list37 = [];
			this.list38 = [];
			this.list39 = [];
			this.list40 = [];

			this.table30 = [
				{value1:'-',
				value2:'-',
				value3:'-',
				value4:'-',
				value5:'-',
				value6:'-',
				value7:'-',
				value8:'-',
				value9:'-',
				value10:'-',
				value11:'-',
				value12:'-',}
			];
			this.table34 = [{value1:'-',
				value2:'-',
				value3:'-',
				value4:'-',
				value5:'-',
				value6:'-',
				value7:'-',
				value8:'-',
				value9:'-',
				value10:'-',
				value11:'-',
				value12:'-',}];
			this.table35 = [{value1:'-',
				value2:'-',
				value3:'-',
				value4:'-',
				value5:'-',
				value6:'-',
				value7:'-',
				value8:'-',
				value9:'-',
				value10:'-',
				value11:'-',
				value12:'-',}];
			this.table36 = [{value1:'-',
				value2:'-',
				value3:'-',
				value4:'-',
				value5:'-',
				value6:'-',
				value7:'-',
				value8:'-',
				value9:'-',
				value10:'-',
				value11:'-',
				value12:'-',}];
			this.table37 = [{value1:'-',
				value2:'-',
				value3:'-',
				value4:'-',
				value5:'-',
				value6:'-',
				value7:'-',
				value8:'-',
				value9:'-',
				value10:'-',
				value11:'-',
				value12:'-',}];
			this.table38 = [{value1:'-',
				value2:'-',
				value3:'-',
				value4:'-',
				value5:'-',
				value6:'-',
				value7:'-',
				value8:'-',
				value9:'-',
				value10:'-',
				value11:'-',
				value12:'-',}];
			this.table39 = [{value1:'-',
				value2:'-',
				value3:'-',
				value4:'-',
				value5:'-',
				value6:'-',
				value7:'-',
				value8:'-',
				value9:'-',
				value10:'-',
				value11:'-',
				value12:'-',}];
			this.table40 = [{value1:'-',
				value2:'-',
				value3:'-',
				value4:'-',
				value5:'-',
				value6:'-',
				value7:'-',
				value8:'-',
				value9:'-',
				value10:'-',
				value11:'-',
				value12:'-',}];
		},
		//飞机获取
		planeInfoByUserId() {
			var _this = this;
			let queryData = {};
			// queryData.userId = 'f0019fdebedb443c98dcb17d88222c38';
			queryData.userId = this.userInfo.id;
			queryData.pageNo = 1;
			queryData.pageSize = 10;
			//获取数据
			this.axios
				.get(this.$apiUrl + '/airhostinginfo/airHostingInfo/list', { params: queryData, headers: { 'X-Access-Token': _this.token, env: 'front' } })
				.then(res => {
					var data = res.data;

					if (data.code == 200) {
						if (data.result.total > 0) {
							this.list29 = data.result.records;
							_this.options = [];
							this.$zui.each(data.result.records, (index, item) => {
								if (index == 0) {
									// _this.value = item.planeName
									this.detail29_label = item.planeName;
									this.currentId = item.id;
									this.detail29 = item;
								}
								let temp = {};
								temp.label = item.planeName;
								temp.value = index;
								temp.id = item.id;
								_this.options.push(temp);
							});
							// console.log(this.detail29);
							this.changeSelect(0);
						}
					} else {
						this.$message({
							message: res.data.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							offset: 20,
							duration: 2000,
						});
						// console.log(res.data.message);
					}
				})
				.catch(err => {
					console.log(err);
					this.$message({
						message: this.$t('token'),
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						offset: 20,
						duration: 2000,
						onClose: () => {
							this.logOut();
							this.$router.push({
								name: 'login'
							});
						}
					});
				});
		},
		//机身及发动机
		getList30() {
			var _this = this;
			let queryData = {};
			queryData.id = this.currentId;
			// console.log(id);
			//获取数据
			this.axios
				.get(this.$apiUrl + '/airhostinginfo/airHostingInfo/queryAirHostingItemHoursByMainId', { params: queryData, headers: { 'X-Access-Token': _this.token } })
				.then(res => {
					// console.log('list30', res.data);
					if (res.data.code == 200) {
						this.list30 = res.data.result.records;
						_this.table30 = [];
						this.$zui.each(this.list30, (index, item) => {
							let temp = {};
							temp.value1 = item.itemName;
							temp.value2 = item.itemType;
							temp.value3 = item.totalHours;
							temp.value4 = item.totalCyclesNum;
							_this.table30.push(temp);
						});
					} else {
						this.$message({
							message: res.data.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							offset: 20,
							duration: 2000,
						});
						// console.log(res.data.message);
					}
				})
				.catch(err => {
					console.log(err);
					this.$message({
						message: this.$t('token'),
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						offset: 20,
						duration: 2000,
						onClose: () => {
							this.logOut();
							this.$router.push({
								name: 'login'
							});
						}
					});
				});
		},
		//月报获取
		getList31(id, airYear, airMonth) {
			var _this = this;
			let queryData = {};
			queryData.hostingInfoId = id;
			queryData.airYear = airYear;
			queryData.airMonth = airMonth;
			// queryData.airMonth = 10;
			queryData.pageNo = 1;
			queryData.pageSize = 10;
			//获取数据

			this.axios
				.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/list', { params: queryData, headers: { 'X-Access-Token': _this.token } })
				.then(res => {
					// console.log(res.data);
					if (res.data.code == 200) {
						if (res.data.result.total > 0) {
							this.list31 = res.data.result.records[0];
							// console.log(this.list31);
							//32、运营统计时段 （根据 31、飞机运营月报基础信息ID获取）
							this.getList32(this.list31.id);
							// 33、行程概要 （根据 31、飞机运营月报基础信息ID获取）
							this.getList33(this.list31.id);
							// 34、当月维修工作 （根据 31、飞机运营月报基础信息ID获取）
							this.getList34(this.list31.id);
							// 35、AD-BS执行情况 （根据 31、飞机运营月报基础信息ID获取）
							this.getList35(this.list31.id);
							// 36、飞机故障\缺陷监控 （根据 31、飞机运营月报基础信息ID获取）
							this.getList36(this.list31.id);
							// 37、备用金额剩余情况 （根据 31、飞机运营月报基础信息ID获取）
							this.getList37(this.list31.id);
							// 38、获取航材 （根据 31、飞机运营月报基础信息ID获取）
							this.getList38(this.list31.id);
							// 39、结构损伤（根据 31、飞机运营月报基础信息ID获取）
							this.getList39(this.list31.id);
							// 40、其他事项（根据 31、飞机运营月报基础信息ID获取）
							this.getList40(this.list31.id);
							//获取评注
							this.getComment(this.list31.id);
							this.setEmpty();
						} else {
							this.$message({
								message: airMonth + '月暂无数据',
								showClose: true,
								type: 'error',
								customClass:'hahaha',
								offset: 20,
								duration: 2000,
							});
						}
					} else {
						this.$message({
							message: res.data.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
						// console.log(res.data.message);
					}
				})
				.catch(err => {
					console.log(err);
					this.$message({
						message: this.$t('token'),
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						offset: 20,
						duration: 2000,
						onClose: () => {
							this.logOut();
							this.$router.push({
								name: 'login'
							});
						}
					});
				});
		},
		//行程统计
		getList32(id) {
			var _this = this;
			let queryData = {};
			queryData.id = id;
			//获取数据
			this.axios
				.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/queryAirHoursCountByMainId', {
					params: queryData,
					headers: {
						'X-Access-Token': _this.token
					}
				})
				.then(res => {
					// console.log(res.data);
					if (res.data.code == 200) {
						this.list32 = res.data.result.records;
						// console.log(this.list32,'list32');
					} else {
						this.$message({
							message: res.data.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
					}
				})
				.catch(err => {
					console.log(err);
					this.$message({
						message: this.$t('token'),
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						duration: 2000,
						offset: 20,
						onClose: () => {
							this.logOut();
							this.$router.push({
								name: 'login'
							});
						}
					});
				});
		},
		//行程概要
		getList33(id) {
			var _this = this;
			let queryData = {};
			queryData.id = id;
			//获取数据
			this.axios
				.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/queryAirTourSummaryByMainId', {
					params: queryData,
					headers: {
						'X-Access-Token': _this.token
					}
				})
				.then(res => {
					// console.log('list33', res.data);
					if (res.data.code == 200) {
						this.list33 = res.data.result.records;
					} else {
						this.$message({
							message: res.data.message,
							showClose: true,
							type: 'error',
							duration: 2000,
							customClass:'hahaha',
							offset: 20
						});
					}
				})
				.catch(err => {
					console.log(err);
					this.$message({
						message: this.$t('token'),
						showClose: true,
						type: 'error',
						duration: 2000,
						customClass:'hahaha',
						offset: 20,
						onClose: () => {
							this.logOut();
							this.$router.push({
								name: 'login'
							});
						}
					});
				});
		},
		//当月维修
		getList34(id) {
			var _this = this;
			let queryData = {};
			queryData.id = id;
			//获取数据
			this.axios
				.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/queryAirRepairDetailByMainId', {
					params: queryData,
					headers: {
						'X-Access-Token': _this.token
					}
				})
				.then(res => {
					// console.log('维护',res.data);
					if (res.data.code == 200) {
						this.list34 = res.data.result.records;
						if(this.list34.length == 0){
							this.table34 = [{value1:'-',
								value2:'-',
								value3:'-',
								value4:'-',
								value5:'-',
								value6:'-',
								value7:'-',
								value8:'-',
								value9:'-',
								value10:'-',
								value11:'-',
								value12:'-',}];
						}else{
							_this.table34 = [];
							this.$zui.each(this.list34, (index, item) => {
								let temp = {};
								temp.value1 = Number(index) + 1;
								temp.value2 = item.repairDateBegin + '~' + item.repairDateEnd;
								temp.value3 = item.repairLevel;
								temp.value4 = item.repairProject;
								temp.value5 = item.remake;
								temp.fkOperationId = item.fkOperationId;
								_this.table34.push(temp)
		
							});
						}
		
						// console.log(_this.table34);
					} else {
						this.$message({
							message: res.data.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
					}
				})
				.catch(err => {
					console.log(err);
					this.$message({
						message: this.$t('token'),
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						offset: 20,
						duration: 2000,
						onClose: () => {
							this.logOut();
							this.$router.push({
								name: 'login'
							});
						}
					});
				});
		},
		//AD/SB 执行情况
		getList35(id) {
			var _this = this;
			let queryData = {};
			queryData.id = id;
			//获取数据
			this.axios
				.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/queryAirAdSbDetailByMainId', {
					params: queryData,
					headers: {
						'X-Access-Token': _this.token
					}
				})
				.then(res => {
					// console.log(res.data);
					if (res.data.code == 200) {
						this.list35 = res.data.result.records;
						if(this.list35.length == 0){
							this.table35 = [{value1:'-',
								value2:'-',
								value3:'-',
								value4:'-',
								value5:'-',
								value6:'-',
								value7:'-',
								value8:'-',
								value9:'-',
								value10:'-',
								value11:'-',
								value12:'-',}];
						}else{
							_this.table35 = [];
							this.$zui.each(this.list35, (index, item) => {
								let temp = {};
								temp.value1 = Number(index) + 1;
								temp.value2 = item.dateDay +'~'+ item.endDateDay;
								temp.value3 = item.serialNum;
								temp.value4 = item.planeDescribe;
								temp.value5 = item.progressContent;
								temp.fkOperationId = item.fkOperationId;
								_this.table35.push(temp)
							});
						}
		
					} else {
						this.$message({
							message: res.data.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
					}
				})
				.catch(err => {
					console.log(err);
					this.$message({
						message: this.$t('token'),
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						offset: 20,
						duration: 2000,
						onClose: () => {
							this.logOut();
							this.$router.push({
								name: 'login'
							});
						}
					});
				});
		},
		//飞机故障/缺陷监控
		getList36(id) {
			var _this = this;
			let queryData = {};
			queryData.id = id;
			//获取数据
			this.axios
				.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/queryAirFaultMonitoringByMainId', {
					params: queryData,
					headers: {
						'X-Access-Token': _this.token
					}
				})
				.then(res => {
					// console.log(res.data);
					if (res.data.code == 200) {
						this.list36 = res.data.result.records;
						if(this.list36.length == 0){
							this.table36 = [{value1:'-',
								value2:'-',
								value3:'-',
								value4:'-',
								value5:'-',
								value6:'-',
								value7:'-',
								value8:'-',
								value9:'-',
								value10:'-',
								value11:'-',
								value12:'-',}];
						}else{
							_this.table36 = [];
							this.$zui.each(this.list36, (index, item) => {
								let temp = {};
								temp.value1 = Number(index) + 1;
								temp.fkOperationId = item.fkOperationId;
								temp.value2 = item.faultDate +'~'+ item.endFaultDate;
								temp.value3 = item.level;
								temp.value4 = item.faultDesc;
								temp.value5 = item.faultReason;
								temp.value6 = item.faultDate;
								_this.table36.push(temp);
							});
						}
		
					} else {
						this.$message({
							message: res.data.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
					}
				})
				.catch(err => {
					console.log(err);
					this.$message({
						message: this.$t('token'),
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						offset: 20,
						duration: 2000,
						onClose: () => {
							this.logOut();
							this.$router.push({
								name: 'login'
							});
						}
					});
				});
		},
		//账单
		getList37(id) {
			var _this = this;
			let queryData = {};
			queryData.id = id;
			//获取数据
			this.axios
				.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/queryAirMoneyRemainingContentByMainId', {
					params: queryData,
					headers: {
						'X-Access-Token': _this.token
					}
				})
				.then(res => {
					// console.log('剩余',res.data);
					if (res.data.code == 200) {
						this.list37 = res.data.result.records;
						if(this.list37.length == 0){
							this.table37 = [{value1:'-',
								value2:'-',
								value3:'-',
								value4:'-',
								value5:'-',
								value6:'-',
								value7:'-',
								value8:'-',
								value9:'-',
								value10:'-',
								value11:'-',
								value12:'-',}];
						}else{
							_this.table37 = [];
							this.$zui.each(this.list37, (index, item) => {
								let temp = {};
								temp.value1 = Number(index) + 1;
								temp.value2 = item.billTime +'~'+item.endBillTime;
								temp.value3 = item.leg;
								temp.value4 = item.project;
								temp.value5 = item.detail;
								temp.value6 = item.amount;
								temp.value7 = item.exchangeRate;
								temp.value8 = item.amountRmb;
								temp.value9 = item.taxRate;
								temp.value10 = item.serviceCharge;
								temp.value11 = item.remark;
								temp.id = item.id;
								temp.fkOperationId = item.fkOperationId;
								_this.table37.push(temp)
							});
						}
		
						// console.log(_this.table37);
					} else {
						this.$message({
							message: res.data.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
					}
				})
				.catch(err => {
					console.log(err);
					this.$message({
						message: this.$t('token'),
						showClose: true,
						type: 'error',
						offset: 20,
						customClass:'hahaha',
						duration: 2000,
						onClose: () => {
							this.logOut();
							this.$router.push({
								name: 'login'
							});
						}
					});
				});
		},
		//航材
		getList38(id) {
			var _this = this;
			let queryData = {};
			queryData.id = id;
			//获取数据
			this.axios
				.get(this.$apiUrl + '/airhostingoperation/airHostingOperation/queryAirMaterialStorageByMainId', {
					params: queryData,
					headers: {
						'X-Access-Token': _this.token
					}
				})
				.then(res => {
					// console.log('list38', res.data);
					if (res.data.code == 200) {
						this.list38 = res.data.result;
						if(this.list38.length == 0){
							this.table38 = [{value1:'-',
								value2:'-',
								value3:'-',
								value4:'-',
								value5:'-',
								value6:'-',
								value7:'-',
								value8:'-',
								value9:'-',
								value10:'-',
								value11:'-',
								value12:'-',}];
						}else{
							_this.table38 = [];
							this.$zui.each(this.list38, (index, item) => {
								let temp = {};
								temp.value1 = Number(index) + 1;
								temp.fkOperationId = item.fkOperationId;
								temp.value2 = item.project;
								temp.value3 = item.partNumberPn;
								temp.value4 = item.partNumberSn;
								temp.value5 = item.inventory;
								temp.value6 = item.periodOfValidity+'~'+item.endPeriodOfValidity;
								temp.value7 = item.remark;
								_this.table38.push(temp)
							});
						}
		
						// console.log(_this.table37);
					} else {
						this.$message({
							message: res.data.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
					}
				})
				.catch(err => {
					console.log(err);
					this.$message({
						message: this.$t('token'),
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						offset: 20,
						duration: 2000,
						onClose: () => {
							this.logOut();
							this.$router.push({
								name: 'login'
							});
						}
					});
				});
		},
		//结构损伤
		getList39(id) {
			var _this = this;
			let queryData = {};
			queryData.id = id;
			//获取数据
			this.axios
				.get(this.$apiUrl +
					'/airhostingoperation/airHostingOperation/queryAirHostingOperationMonitoringByMainId', {
						params: queryData,
						headers: {
							'X-Access-Token': _this.token
						}
					})
				.then(res => {
					// console.log('list39', res.data);
					if (res.data.code == 200) {
						this.list39 = res.data.result.records;
		
						if(this.list39.length == 0){
							this.table39 = [{value1:'-',
								value2:'-',
								value3:'-',
								value4:'-',
								value5:'-',
								value6:'-',
								value7:'-',
								value8:'-',
								value9:'-',
								value10:'-',
								value11:'-',
								value12:'-',}];
						}else{
							_this.table39 = [];
							this.$zui.each(this.list39, (index, item) => {
								let temp = {};
								temp.value1 = Number(index) + 1;
								temp.value2 = item.dateDay +'~'+ item.endDateDay;
								temp.value3 = item.monitoringProject;
								temp.value4 = item.monitoringNum;
								temp.value5 = item.remark;
								temp.id = item.id;
								temp.fkOperationId = item.fkOperationId;
								_this.table39.push(temp)
							});
						}
		
						// console.log(_this.table37);
					} else {
						this.$message({
							message: res.data.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
					}
				})
				.catch(err => {
					console.log(err);
					this.$message({
						message: this.$t('token'),
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						duration: 2000,
						offset: 20,
						onClose: () => {
							this.logOut();
							this.$router.push({
								name: 'login'
							});
						}
					});
				});
		},
		//其它
		getList40(id) {
			var _this = this;
			let queryData = {};
			queryData.id = id;
			//获取数据
			this.axios
				.get(this.$apiUrl +
				'/airhostingoperation/airHostingOperation/queryAirHostingOperationOthersByMainId', {
					params: queryData,
					headers: {
						'X-Access-Token': _this.token
					}
				})
				.then(res => {
					// console.log('剩余',res.data);
					if (res.data.code == 200) {
						this.list40 = res.data.result.records;
						if(this.list40.length == 0){
							this.table40 = [{value1:'-',
								value2:'-',
								value3:'-',
								value4:'-',
								value5:'-',
								value6:'-',
								value7:'-',
								value8:'-',
								value9:'-',
								value10:'-',
								value11:'-',
								value12:'-',}];
						}else{
							_this.table40 = [];
							this.$zui.each(this.list40, (index, item) => {
								let temp = {};
								temp.value1 = Number(index) + 1;
								temp.value2 = item.otherDate +'~'+ item.endOtherDate;
								temp.value3 = item.otherProject;
								temp.value4 = item.otherContent;
								temp.value5 = item.remark;
								temp.id = item.id;
								temp.fkOperationId = item.fkOperationId;
								_this.table40.push(temp)
							});
						}
		
						// console.log(_this.table37);
					} else {
						this.$message({
							message: res.data.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
		
					}
				})
				.catch(err => {
					console.log(err);
					this.$message({
						message: this.$t('token'),
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						offset: 20,
						duration: 2000,
						onClose: () => {
							this.logOut();
							this.$router.push({
								name: 'login'
							});
						}
					});
				});
		},
		chageDate() {
			this.$refs.date.focus();
		},
		getComment(id){
			let queryData = {};
			queryData.id = id
			ownerComment['get'](queryData).then(res => {
				// console.log(res);
				if (res.code == 200) {
					// console.log(res.result.records);
					if (res.result.total > 0) {
						this.commentList = res.result.records;
					}
				} else {
					this.$message({
						message: res.message,
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						duration: 2000,
						offset: 20
					});
					// console.log(res.message);
				}
			});
		},
	}
};
</script>

<style lang="scss" scoped>
.messageBox {
	width: 100%;
	height: 550px;
	flex: 1;
	display: flex;
	flex-direction: column;

	.msg-title {
		height: 50px;
		padding: 15px 20px 0px;
		// background-color: black;
		box-sizing: border-box;
		ul {
			display: flex;
			justify-content: space-between;
			align-items: center;
			list-style: none;
			margin: 0;
			padding: 0;
			height: 100%;
			width: 100%;
			border-bottom: 1px solid rgba($color: #000000, $alpha: 0.6);
			li {
				margin: 0;
				padding: 0;
				flex: 1;
				height: 100%;
				display: flex;
				align-items: center;
				span {
					background-color: #f0edf1;
					color: #000000;
					width: 48%;
					height: 100%;
					cursor: pointer;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					&.current {
						background-color: #000000;
						color: #f0edf1;
					}
				}
				&:first-child {
					display: flex;
					justify-content: flex-start;
					width: 30% !important;
				}
				&:last-child {
					display: flex;
					width: 30% !important;
					justify-content: flex-end;
				}
			}
		}
	}
	.msg-body {
		flex: 1;
		overflow-y: scroll;
		// width: 100%;
	}
}
// 手机适配
@media screen and (max-width: 960px) {
	.messageBox {
		.msg-title {
			padding: 15px 5px 0px;
			ul {
				li {
					span {
						font-size: 10px !important;
					}
					&:first-child {
						width: 28% !important;
					}
					&:last-child {
						width: 25% !important;
					}
				}
			}
		}
	}
}
</style>
