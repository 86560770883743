<template>
	<div>
		<div class="mask" v-if="isShow" >
			<div class="mask-main zui-flex-column">
				<div class="msg-title">个人信息修改</div>
				<div class="msg-body">
					<div class="flex_column" style="margin-right: 20px">
						<el-upload class="avatar-uploader" :action="$apiUrl + '/sys/common/upload'"
							:show-file-list="false" :on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload">
							<img :src="$apiUrl +'/'+avatar" class="zui-rounded-circle" style="width: 90px; height: 90px"
								alt="" />
							<div class="zui-m-t-10">{{$t('changeAvatar')}}</div>
						</el-upload>
					</div>
					<div style="margin-bottom: 10px">
						<div style="display: flex;align-items: center; margin-bottom: 10px">
							<div style="width: 90px" class="justify">
								{{$t('formLabel.realname')}}:
								<span></span>
							</div>
							<el-input class="flex_1" v-model="formData.realname" size="mini" :disabled="isInput">
							</el-input>
							<div @click="inputEvn"><img style="vertical-align: inherit" src="../assets/image/12.png" />
							</div>
						</div>

						<div style="display: flex;align-items: center; margin-bottom: 10px">
							<div style="width: 90px" class="justify">
								{{$t('formLabel.email')}}:
								<span></span>
							</div>
							<el-input class="flex_1" v-model="formData.email" size="mini" :disabled="isInput">
							</el-input>
							<div @click="inputEvn" style="margin-left: 10px"><img style="vertical-align: inherit"
									src="../assets/image/12.png" /></div>
						</div>

						<div style="display: flex;align-items: center; margin-bottom: 10px">
							<div style="width: 90px" class="justify">
								{{$t('formLabel.contactPhone')}}:
								<span></span>
							</div>
							<el-input class="flex_1" v-model="formData.phone" size="mini" :disabled="true"></el-input>
						</div>
					</div>
				</div>
				<div class="mask-btn">
					<span @click="hideMask" >取<i></i>消</span>
					<span @click="editUser" >确<i></i>认</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import signMd5Utils from '../assets/lib/signMd5Utils.js';
	import { mapState, mapMutations } from 'vuex';
	export default {
		props: {
		},
		data() {
			return {
				isShow:false,
				// 弹窗
				avatar: '../assets/image/11.png',
				formData: {
					realname: '',
					email: '',
					phone: '',
					avatar: ''
				},
				isInput: true,
			}
		},
		computed: {
			...mapState(['userInfo', 'token', 'isLogin'])
		},
		beforeMount: function() {

			if (this.isLogin == false) {
				// console.log(111);
			}
			this.formData.realname = this.userInfo.realname;
			this.formData.email = this.userInfo.email;
			this.formData.phone = this.userInfo.phone;
			if (this.userInfo.avatar) {
				this.avatar = this.userInfo.avatar
				this.formData.avatar = this.userInfo.avatar
			} else {
				this.avatar = require('../assets/image/11.png');
			}

		},
		methods: {
			...mapMutations(['logOut']),
			showMask(){
				this.isShow = true;
			},
			hideMask(){
				this.isShow = false;
			},
			editUser() {
				//定义表单规则
				var rule = [{
						name: "realname",
						checkType: "notnull",
						checkRule: "",
						errorMsg: this.$t('formMsg.realname')
					},
					{
						name: "email",
						checkType: "email",
						checkRule: "",
						errorMsg: this.$t('formMsg.email')
					},
				];
				if (!this.$zuiChecker.check(this.formData, rule)) {
					this.$message({
						message: this.$zuiChecker.error,
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						duration: 2000,
						offset: 20
					});
					return;
				}
				let _this = this;
				let url = this.$apiUrl + '/sys/user/appEdit';
				let sign = signMd5Utils.getSign(url, this.formData);
				let timestamp = signMd5Utils.getDateTimeToString();
				// console.log(this.formData);
				this.axios
					.put(url, this.formData, {
						headers: {
							'X-Access-Token': _this.token,
							'X-Sign': sign,
							'X-TIMESTAMP': timestamp
						}
					})
					.then(res => {
						// console.log(res);
						if (res.data.code == 0) {
							let userData = this.userInfo;
							userData.avatar = this.formData.avatar;
							userData.realname = this.formData.realname;
							userData.phone = this.formData.phone;
							userData.email = this.formData.email;
							this.$store.dispatch('saveUserInfo', userData);
							this.$message({
								message: '修改成功',
								showClose: true,
								type: 'success',
								customClass:'hahaha',
								offset: 20,
								duration: 2000,
							});
							this.inputEvn();
							this.hideMask();
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass:'hahaha',
								duration: 2000,
								offset: 20
							});
							// console.log(res.message);
						}
					})
					.catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('token'),
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
			},
			inputEvn() {
				this.isInput = !this.isInput;
			},
			handleAvatarSuccess(res, file) {
				// console.log(res,file);
				this.inputEvn();
				if (res.code == 0) {
					this.formData.avatar = res.message
				}
				this.avatar = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt2M) {
					// this.$message.error('上传头像图片大小不能超过 2MB!');
					this.$message({
						message:'上传头像图片大小不能超过 2MB!',
						type:'error',
						customClass:'hahaha',
						duration: 2000,
					})
				}
				return isLt2M;
			}
		}
	}
</script>

<style lang="scss" scoped>
.mask {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba($color: #000000, $alpha: .6);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 999;

		.mask-main {
			width: 90%;
			min-height: 300px;
			border-radius: 4px;
			background-color: white;

			.msg-title {
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #AFAFAF;
				color: white;
				box-shadow: 0 4px 5px 2px rgba(14, 10, 14, 0.2);
			}

			.msg-body {
				display: flex;
				flex-direction: column;
				padding: 10px;
				box-sizing: border-box;
				flex: 1;
			}

			.mask-btn {
				height: 60px;
				display: flex;
				justify-content: space-around;
				align-items: center;

				span {
					&:last-child {
						background-color: #DBB965;
					}

					display: inline-block;
					width: 30%;
					height: 36px;
					background-color: #C6C6C6;
					border-radius: 4px;
					color:white;
					font-size: 14px;
					display: flex;
					justify-content: center;
					align-items: center;

					i {
						display: inline-block;
						width: 16px;
					}
				}

			}
		}
	}
</style>
