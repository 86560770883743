<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
    data () {
      return {
      }
  },
  components: {
    
  },
  methods: {

  },
  mounted () {
    
  }
}
</script>

<style lang="scss">
// @import './assets/scss/reset.scss';
@import './assets/scss/config.scss';
@import './assets/scss/button.scss';
@import './assets/styles/index.scss';
@import './assets/zui/style/common.css';
@import './assets/styles/media.css';

.hahaha{
		z-index:3999 !important;
	}
::-webkit-scrollbar { 
  // width: 0px !important; 
}
body{
	padding-right: 0 !important;
	// text-transform: uppercase;
}
input[type=file] {
  display: none;
}
* {
    margin: 0px;
    padding: 0px;
}

html,
body {
    height: 100%;
}
.el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
} 
.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}
.el-upload-list--picture-card .el-progress {
  width: 100px;
  left: 56%;
}
.el-progress--circle .el-progress__text, .el-progress--dashboard .el-progress__text {
  left: -4px;
}
// lxl - css
.padding-0{
	padding:0 !important
}
.maign-0{
	margin:0 !important
}
.h-100 {
	height: 100%;
}
.box-shadow {
	box-shadow: 0px 2px 10px #d1d1d1;
}
.cursor {
	cursor:pointer
}
.hide-scroll {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: auto;
  overflow-y: auto;
}
.body-bg-color {
	background-color: white !important;
}
#app {
	background-color: white !important;
}
.btnblock{
	width: 8.333333333%;
	position: absolute;
	// height: 100px;
	right: 0;
	top: 20%;
	display: flex;
	justify-content: center;
}
.btnCenter{
	height: 1em;
	width: calc(2em - 10px);
	display: flex;
	justify-content: center;
}
.back {
	padding: 4px 8px;
	font-size: 10px;
	right:5%;
	top:20%;
	border-radius: 4px;
}
.jinBtn {
	background-color: #DBB96E !important;
	text-transform: uppercase;
	display: flex;
	align-items: center;
}
.jinButton {
	background-color: #DBB96E !important;
	outline-style: none !important;
	color:white;
}
.jinColor {
	color: #DBB96E !important;
}
.jinBorder {
	border:1px solid #DBB96E !important;
}
//加载更多
	.moreBox {
		display: flex;
		justify-content: center;
		align-items: center;

		.more-load {
			cursor: pointer;
			width: 40%;
			background-color: #DBB96E !important;
			padding-top: 5px;
			padding-bottom: 5px;
			border-radius: 4px;
			color: white;
			font-size: 12px;
			text-align: center;
		}
	}
	// 分栏线
.line {
	border-top: 1px solid black;
	width: 100%;
	position: relative;
	.h1 {
		font-size: 25px;
		// margin-top: 20px;
	}
	.circle {
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 8px;
		border: 1px solid black;
		background-color: black;
		position: absolute;
		top: -5px;
	}
	.circle1 {
		left: 30%;
	}
	.circle2 {
		left: 50%;
		background-color: white;
		&.flag::before {
			content: '';
			width: 240px;
			height: 60px;
			border: 1px solid black;
			position: absolute;
			border-radius: 12px;
			top: -120px;
			left: 50%;
			margin-left: -120px;
		}
		&.flag::after {
			content: '';
			width: 1px;
			height: 60px;
			background-color: black;
			position: absolute;
			left: 50%;
			top: -60px;
		}
	}
	.circle3 {
		right: 30%;
	}
}
//飞机宽高
/deep/ .airPlane-img {
	padding-top: 60%;
	width: 100%;
	position: relative;
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		top:0;
	}
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
	border-color: #edb232 !important;
	background-color: #edb232 !important;
}
/deep/ .el-input__inner:hover {
	border-color: #edb232 !important;
}

</style>
