<template>
	<div class="zui-bg-white">
		<div class="zui-box zui-flex-column zui-justify-start zui-align-center">
			<!-- top -->
			<div class="top zui-w-100 zui-relative" :style="topNote">
				<div class="zui-w-100 zui-h-100 zui-flex-column zui-align-center zui-justify-center zui-border-box">
					<p class="top-1 zui-text-dark" v-html="detail.p1Title "></p>
					<!-- <p class="top-1 zui-text-dark">世界一流的专业能力</p> -->
					<p class="top-2 zui-text-dark" v-html="detail.p1Content"></p>
					<!-- <p @click="$router.push({ name: 'companyProfile' })"
						class="zui-font-sm zui-text-white zui-pointer zui-bg-dark zui-border-radius-small top-btn">
						{{ $t('index.detail') }}
					</p> -->
				</div>
			</div>
			<!-- 推荐机型 -大屏-幻灯片 -->
			<div class="line visible-md visible-lg" style="margin-top: 120px;">
				<span class="circle circle1"></span>
			<!-- 	<span class="circle flag circle2"></span> -->
				<span class="circle circle3"></span>
				<div class="zui-w-100 h1 zui-text-center">{{ $t('index.recommend') }}</div>
			</div>
			<!-- 推荐机型 -小屏-幻灯片 -->
			<div class="RecommendedModels visible-sm visible-xs">
				<!-- 顶部的 -->
				<div class="hangar_tj">
					<div class="heng"></div>
					<div class="kuang flex_row" :class="language =='en' ? 'kuang_en' : '' ">{{ $t('index.recommend') }}</div>
				</div>
			</div>
			<vue-seamless-scroll  :data="airInfos1" :class-option="optionSwitch1"
				class="seamless-warp3">
				<span slot="left-switch" class="left-arrow"></span>
				<span slot="right-switch" class="right-arrow"></span>
				<ul class="item" ref="ulBox">
					<li v-for="(item, index) in airInfos1" class="img-list" :key="index" @click="toDetail(item)">
						<el-image class="img zui-border-radius-small" :src="item.bannerPictures|swiperImg" fit="fill">
						</el-image>
						<div class="desc zui-text-center">{{item.title}}</div>
						<div class="image-tag2" v-if="item.sale=='Y'">
							<!-- <img :src="item.sale=='Y'?'https://www.astroairs.com/public/images/sell_out.png':''" style="width:20%;"> -->
							<div class="tag">Sold Out</div>
						</div>
					</li>
				</ul>
			</vue-seamless-scroll>

			<div class="line visible-md visible-lg">
				<span class="circle circle1"></span>
				<!-- <span class="circle circle2"></span> -->
				<span class="circle circle3"></span>
			</div>
			<div class="moreBox zui-pointer">
				<div @click="$router.push({ name: 'hangar' })" class="moreBtn1">{{ $t('index.findAir') }}</div>
			</div>
			<!-- 第二张图片 -->
			<div class="top zui-w-100" :style="midNote">
				<div class="zui-h-100 img2Box zui-border-box">
					<p class="img2-1 zui-text-dark">{{ detail.p2Title }}</p>
					<p class="img2-3 zui-text-dark" v-html="detail.p2Content"></p>
				</div>
			</div>
			<!-- 行业新闻  -大屏 -->
			<div class="line visible-md visible-lg" style="margin-top: 120px;">
				<span class="circle circle1"></span>
				<!-- <span class="circle flag circle2"></span> -->
				<span class="circle circle3"></span>
				<div class="zui-w-100 h1 zui-text-center">{{ $t('index.news') }}</div>
			</div>
			<!-- 行业新闻  -小屏 -->
			<div class="RecommendedModels visible-sm visible-xs ">
				<!-- 顶部的 -->
				<div class="hangar_tj">
					<div class="heng"></div>
					<div class="kuang flex_row">{{ $t('index.news') }}</div>
				</div>
			</div>
			<!-- 行业动态 -->
			<el-row type="flex" :gutter="80" justify="space-around" class="zui-w-100 new-box" :style="boxHeight">
				<el-col v-for="(item, index) in listNewsData" :key="item.id" :span="8" :lg="8" :md="12" :sm="12"
					:xs="24" :class="className[index]">
					<div class="zui-flex-column   zui-bg-white zui-align-center zui-overflow-hidden"
						@click="toNewsDetail(item)">
						<div class="zui-relative new-img">
							<el-image class="img zui-border-radius-small" :src="$apiUrl + '/' + item.publishImage"
								fit="fill">
							</el-image>
							<!-- <img class="zui-w-100 zui-border-radius-small new-img"
								:src="$apiUrl + '/' + item.publishImage" /> -->
							<span class="new-tag zui-absolute zui-position-tl">{{ item.titleType_dictText }}</span>
						</div>
						<div class="zui-border-box zui-p-10 zui-w-100 news-desc">
							<span class="new-date zui-inline-block zui-w-100 zui-text-left">{{ item.createTime }}</span>
							<p class="new-title zui-w-100 zui-text-left">{{ item.titleName }}</p>
							<span class="new-desc  zui-inline-block">{{ item.introduction }}</span>
						</div>
					</div>
				</el-col>
			</el-row>
			<div class="line visible-md visible-lg">
				<span class="circle circle1"></span>
				<span class="circle circle2"></span>
				<span class="circle circle3"></span>
			</div>
			<div class="moreBox zui-pointer">
				<div @click="$router.push({ name: 'industryNews' })" class="moreBtn1">{{ $t('index.more') }}</div>
			</div>
			<!-- 底部 -->
			<div class="bottomBox" :style="boxHeight">
				
				<el-row type="flex" justify="center" align="middle" class="zui-w-100 zui-h-100" >
					
					<el-col :lg="8" :md="12" :sm="12" :xs="20"  >
						<div class="new-form-item" style="height: 50%; background-color: rgba(255,255,255,.6);box-shadow: 5px 5px 10px rgba(0,0,0,.3); padding:30px ;">
							<p class="bottom-1">{{ $t('index.find') }}</p>
							<p class="bottom-2">{{ $t('index.plane') }}</p>
							<el-select class="bottom-3" size="mini" v-model="brandType" @change="changeType"
								:placeholder="$t('index.planeBrand')">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
							<el-select class="bottom-3" size="mini" v-model="brandNo" @change="changeBrandNo"
								:placeholder="$t('index.planeType')">
								<el-option v-for="item in brandNoArr[currentType]" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
							<div class="bottom-4" @click="findPlane">{{ $t('index.searchPlane') }}</div>
						</div>
						
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>
<script>
	import $config from "../api/config.js"
	import {
		getHangars,
		getNews,
		getBrandAndType,
		getAirFrontPageSetting
	} from '../api/api';
	import vueSeamlessScroll from 'vue-seamless-scroll';
	import { mapState } from 'vuex';
	export default {
		name: 'index',
		components: {
			vueSeamlessScroll
		},
		data() {
			return {
				isShow: false,
				liW: 510,
				showScroll: true,
				data: {},
				// 图片父容器高度
				bannerHeight: 2000,
				// 幻灯片
				carouselHeight: 600,
				// 浏览器宽度
				screenWidth: 0,
				cardType: '',
				options: [],
				value: '',
				listData: [],
				listNewsData: [],
				currentType: 0,
				brandType: '',
				brandNo: '',
				brandNoArr: [],
				current: 0,
				typeName1: '',
				airInfos1: [],
				className: ['new-item', 'new-item hidden-xs-only hidden-sm-and-only', 'new-item hidden-md-and-down'],
				searchQuery: {
					brand: 0,
					type: 0,
					distance: 0,
					price: 0,
					seat: 0
				},
				detail: {}
			};
		},
		computed: {
			...mapState(['language']),
			optionSwitch1() {
				return {
					// limitMoveNum: 5,
					switchSingleStep: this.liW,
					direction: 2,
					openTouch:false,
					openWatch:true,
					autoPlay: false,
					switchOffset: 10,
					hoverStop: true,
					isSingleRemUnit: true,
					navigation: true
				};
			},
			topNote() {
				// banner大小以及背景
				if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
					if (this.detail) {
						let dom = {
							height: this.bannerHeight + 'px',
							backgroundImage: 'url(' + this.$apiUrl + '/' + this.detail.p1Img + ')',
							backgroundSize: '180%',
							backgroundPosition: "45% 70%",
						};
						return dom;
					} else {
						return {}
					}
				} else {
					if (this.detail) {
						let dom = {
							height: this.bannerHeight + 'px',
							backgroundImage: 'url(' + this.$apiUrl + '/' + this.detail.p1Img + ')',
							backgroundSize: 'cover',
						};
						return dom;
					} else {
						return {}
					}
				}
			},
			boxHeight(){
				if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
					if (this.detail) {
						let dom = {
							height: this.bannerHeight + 'px',
							overflow:'hidden'
						};
						return dom;
					} else {
						return {}
					}
				}else{
					return {}
				}
				
			},
			midNote() {
				if (this.detail) {
					let dom = {
						height: this.bannerHeight + 'px',
						backgroundImage: 'url(' + this.$apiUrl + '/' + this.detail.p2Img + ')'
					};
					return dom;
				} else {
					return {}
				}
			}
		},
		filters: {
			swiperImg: function(value) {
				// console.log(value);
				if ((value.search("http") != -1) && (value.search("https") != -1)) {
					return value
				} else {
					return $config.apiUrl + '/' + value
				}
			}
		},
		created: async function() {
			if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
				this.cardType = 'a';
				// this.$nextTick(()=>{

				// })
				this.bannerHeight = (1800 / 1920) * this.screenWidth;
			} else {
				this.cardType = 'a';
			}
			await this.getData();

		},

		methods: {
			changeType: function(e) {
				this.searchQuery.brand = e;
				this.$zui.arrayEach(this.options, (v, k) => {
					if(v.value==0){
						this.currentType = 0
						return
					}
					if (v.value == e) {
						this.currentType = k - 1;
						
					}
				});
			},
			// 跳转详情页
			toDetail(item) {
				this.$router.push({
					name: 'airplaneDetail',
					params: {
						planeInfo: item
					}
				});
			},
			toNewsDetail(item) {
				this.$router.push({
					name: 'industryNewsDetail',
					params: {
						detail: item
					}
				});
			},
			changeBrandNo: function(e) {
				//console.log(e);
				this.searchQuery.type = e;
			},
			findPlane: function() {
				this.$router.push({
					name: 'search',
					params: {
						search: this.searchQuery
					}
				});
			},
			async getData() {
				//首页图片和文章设置查询
				getAirFrontPageSetting().then(res => {
					// console.log(res);
					if (res.code == 200) {
						this.detail = res.result;
						// console.log(this.detail)
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
					}
				});
				getHangars({
					pageSize: 12
				}).then(res => {
					// console.log(res);
					if (res.code == 200) {
						this.typeName1 = res.result.records[0].typeName;
						this.airInfos1 = res.result.records[0].airInfos;
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
					}
				});

				//获取行业新闻
				// console.log(this.listData);
				getNews({
					column: 'createTime',
					pageSize: 3,
					titleType: 0,
					_t: Date.parse(new Date())
				}).then(res => {
					// console.log(res);
					if (res.code == 200) {
						this.listNewsData = res.result.records;
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
						//console.log(res.message);
					}
				});
				getBrandAndType().then(res => {
					// console.log(res);
					if (res.code == 200) {
						let data = res.result.brandVoList;
						let types = [{
							value: 0,
							label: '全部'
						}];
						let brandNoArr = [];
						this.$zui.arrayEach(data, (item, index) => {
							let temp = {};
							temp.label = item.brandName;
							temp.value = index + 1;
							let temp2 = [];
							if (item.planeTypeVoList.length > 0) {
								this.$zui.arrayEach(item.planeTypeVoList, (v, k) => {
									let temp1 = {};
									temp1.label = v.typeName;
									temp1.value = k;
									temp2.push(temp1);
								});
							}
							types.push(temp);
							brandNoArr.push(temp2);
						});
						this.options = types;
						this.brandNoArr = brandNoArr;
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
						//console.log(res.message);
					}
				});
			},
			carouselChange: function(e) {
				console.log(e,111111);
				let item = this.listData[0].lists[e];
				this.$router.push({
					name: 'airplaneDetail',
					params: {
						planeInfo: item
					}
				});
			},
			setSize: function() {
				let _this = this;
				this.isShow = false;
				// 通过浏览器宽度(图片宽度)计算高度
				// 通过浏览器宽度(图片宽度)计算高度
				if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
					this.bannerHeight = (1360 / 1920) * this.screenWidth;
				}else{
					this.bannerHeight = (860 / 1920) * this.screenWidth;
				}
				this.carouselHeight = (350 / 1920) * this.screenWidth;
				this.centerHeight = (1080 / 1920) * this.screenWidth;
				this.isShow = true;
				this.showScroll = false;
				// this.$nextTick(()=>{
				// })
				_this.showScroll = true;
			}
		},
		mounted() {
			// this.login()

			// 首次加载时,需要调用一次
			this.screenWidth = window.innerWidth;
			// 窗口大小发生改变时,调用一次
			this.setSize();
			// 窗口大小发生改变时,调用一次
			window.onresize = () => {
				this.screenWidth = window.innerWidth;
				this.setSize();
			};
			setTimeout(() => {
				// console.log(this.$refs.ulBox.offsetWidth);
				let liWidth = this.$refs.ulBox.offsetWidth / this.airInfos1.length
				// console.log(parseInt(liWidth));
				this.liW = parseInt(liWidth);
			}, 800)
		}
	};
</script>
<style lang="scss" scoped>
	@import '../assets/scss/config.scss';
	@import '../assets/scss/mixin.scss';
	$primary-color: #333;
	.img-list{
		position: relative;
	}
	.image-tag2{
		position: absolute;
		top: 10px;
		left: 10px;
	}
	.tag{
		padding:10px 30px;
		font-size: 16px;
		color: white;
		background-color: #000;
	}
	
	.zui-box {
		.top {
			// background-image: url('../assets/image/03.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;

			.top-1 {
				font-size: 20px;
			}

			.top-2 {
				font-size: 16px;
				margin-top: 20px;
			}

			.top-btn {
				padding: 4px 12px;
				margin-top: 40px;
				font-size: 12px;
				background-color: #DBB96E;
				box-shadow: 0 0 5px 2px #7d7d7d;
			}

			.img2Box {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-top: 20px;

				.img2-1 {
					color: white;
					font-size: 20px;
				}

				.img2-2 {
					color: white;
					font-size: 24px;
				}

				.img2-3 {
					color: white;
					font-size: 14px;
					width: 70%;
				}
			}

		}

		.bottomBox {
			background-image: url('../assets/image/14.jpg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			width: 100%;
			height: 500px;

			.new-form-item {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-content: center;

				.bottom-1 {
					color: black;
					font-size: 12px;
				}

				.bottom-2 {
					color: black;
					font-size: 24px;
				}

				.bottom-3 {
					color: black;
					margin-top: 10px;
					font-size: 12px;

					.el-input__inner {
						background-color: black !important;
						border: none !important;
					}
				}

				.bottom-4 {
					color: black;
					font-size: 12px;
					width: 100%;
					height: 30px;
					line-height: 30px;
					text-align: center;
					border: 1px solid black;
					padding: 0 !important;
					margin: 0 !important;
					cursor: pointer;
					margin-top: 30px !important;
				}
			}
		}

		.new-box {

			// height: 350px;
			.new-item {
				height: 100%;
				box-sizing: border-box;
				padding: 20px 0;

				.new-tag {
					padding: 6px 12px;
					background-color: dimgray;
					color: white;
					font-size: 14px;
					top: 5px;
					left: -5px;
				}

				/deep/ .img {
					background-color: #999;
					padding-top: 60%;
					width: 100%;
					position: relative;

					img {
						position: absolute;
						width: 100%;
						height: 100%;
						top: 0;
					}
				}

				.new-img {
					width: 100%;
					// height: 260px;
				}

				.news-desc {
					height: 160px;

					.new-date {
						font-size: 12px;

						color: darkgray;
					}

					.new-title {
						font-size: 18px;
					}

					.new-desc {
						font-size: 14px;
						color: gray;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
				}
			}
		}
	}

	.swiperBox {
		width: 100%;
		box-sizing: border-box;
		padding: 0 20px;
	}

	.moreBox {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 15px 0;
		width: 100%;

		.moreBtn {
			width: 60%;
			text-align: center;
			padding: 5px 0;
			box-sizing: border-box;
			color: white;
			font-size: 14px;
			border-radius: 8px;
			background-color: #dab96b;
		}

		.moreBtn1 {
			text-align: center;
			padding: 5px 10px;
			box-sizing: border-box;
			color: white;
			font-size: 14px;
			border-radius: 4px;
			// border: 1px solid #dab96b;	
			background-color: #DBB96E;
			box-shadow: 0 0 0.133333rem 0.053333rem #7d7d7d;
			
		}
	}

	.RecommendedModels {
		width: 100%;
		height: 20px;
		position: relative;
		margin-bottom: 50px;
	}

	.heng {
		width: 90%;
		background-color: #000000;
		height: 1px;
		margin: 0 auto;
		position: absolute;
		top: 32px;
		left: 50%;
		transform: translate(-50%, -25%);

		&::after {
			content: '';
			position: absolute;
			background-image: url('../assets/image/06.png');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			width: 15px;
			height: 30px;
			top: -40px;
			left: 50%;
			z-index: 10;
		}
	}

	.kuang {
		width: 133px;
		height: 23px;
		line-height: 0.32rem;
		text-align: center;
		background-color: #000000;
		color: $colorG;
		position: absolute;
		top: 25px;
		left: 50%;
		transform: translate(-50%, -25%);
	}
	.kuang_en{
		height: auto !important;
		padding: 2px;
	}
	.img2-box {
		background-image: url('../assets/image/03.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}

	.hangar_tj {
		width: 100%;
		text-align: center;
		margin: 20px auto;
	}

	.btnColor {
		width: 147px;
		height: 32px;
	}

	.carousel-img {
		width: 100%;
	}

	.carousel-desc {
		height: 60px;
		width: 100%;
	}

	.el-carousel__mask {
		background-color: transparent !important;
	}

	.seamless-warp3 {
		overflow: hidden;
		width: 80vw;
		margin: 0 auto;

		.left-arrow,
		.right-arrow {
			position: relative;
			display: inline-block;
			width: 40px;
			height: 40px;
			border-radius: 50%;

			background-color: white;
			cursor: pointer;
			z-index: 100;
			box-shadow: 0 0 8px 2px #ececec;

			&:hover {
				background-color: white;
			}

			&::before {
				position: absolute;
				content: '';
				width: 16px;
				height: 16px;
				top: 12px;
				left: 15px;
				border: 2px solid #dbb96e;
			}
		}

		.left-arrow::before {
			border-right: 0;
			border-bottom: 0;
			transform: rotate(-45deg);
		}

		.right-arrow::before {
			border-left: 0;
			border-top: 0;
			left: 9px;
			transform: rotate(-45deg);
		}

		ul.item {
			display: flex;
			justify-content: flex-start;
			flex-wrap: nowrap;
			margin: 0;
			padding: 0;
			width: 100%;
			list-style: none;

			// height: calc( (260/(1920*80%))*80vw );
			li {
				width: 26.6vw;
				display: flex;
				padding: 10px;
				height: 100%;
				box-sizing: border-box;
				flex-direction: column;

				/deep/ .img {
					background-color: #999;
					padding-top: 60%;
					width: 100%;
					position: relative;

					img {
						position: absolute;
						width: 100%;
						height: 100%;
						top: 0;
					}
				}

				.desc {
					// height: 60px;
					font-size: 12px;
					padding: 10px 10px 0;
				}
			}
		}
	}



	/* Small Devices, Tablets */
	@media only screen and (max-width: 992px) {
		.image-tag1{
			top: 5px !important;
			left: 5px !important;
			img{
				width: 20% !important;
			}
		}
		
		/deep/	.left-arrow,
			{
				width: 30px !important;
				height: 30px !important;
		
				&::before {
					width: 12px !important;
					height: 12px !important;
					top: 8px !important;
					left: 13px !important;
				}
			}
		/deep/ .right-arrow {
				width: 30px !important;
				height: 30px !important;
		
				&::before {
					width: 12px !important;
					height: 12px !important;
					top: 8px !important;
					left: 8px !important;
				}
			}
		
		.right .contentRight-r li .li_l{
			width:40%;
		}
		.top {
			.top-1 {
				margin-top: 0 !important;
				// font-size: 12px;
			}

			.top-2 {
				margin-top: 0 !important;
			}

			.top-btn {
				margin-top: 10px !important;
			}

			.img2Box {
				justify-content: center;
				padding-top: 0px;

				.img2-1 {
					font-size: 14px !important;
				}

				.img2-3 {
					font-size: 10px !important;
				}
			}


			.zui-h4 {
				font-size: 20px;
			}

			.zui-font-md {
				font: 10px !important;
			}

			.top-btn {
				font-size: 12px;
				padding-top: 5px;
				padding-bottom: 5px;
				padding-left: 10px;
				padding-right: 10px;
			}
		}

		.swiperBox {
			padding: 0 10px;
		}

		/deep/ .seamless-warp3 {
			width: 70vw;

			ul.item {
				li {
					width: 70vw !important;
				}
			}


		}
	}
</style>
