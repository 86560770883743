import Vue from 'vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
// 适配 插件
import 'lib-flexible'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css';

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
// juq boot
import $ from 'jquery'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../src/assets/styles/index.scss'
import $config from './api/config.js';
//引入全局mixins
import Mixin from './mixins';
Vue.mixin(Mixin);
// vuex
import store from './store'
import App from './App.vue'
// 加载storage
import $storage from './storage'
Vue.prototype.$storage = $storage;
Vue.prototype.$apiUrl = $config.apiUrl //图片网址
//全局挂载 zuiUtil工具
import $zui from './assets/zui/util/util.js'
Vue.prototype.$zui = $zui;
// 引入 pdf 导出 工具
import htmlToPdf from './util/htmlToPdf.js'
Vue.use(htmlToPdf)
// 引入 多语言 工具
import i18n from './util/language/index.js'
// 引入 多语言 工具
import $zuiChecker from './assets/zui/util/checker.js';
Vue.prototype.$zuiChecker = $zuiChecker;


const mock = false
if (mock) {
	require('./mock/api')
}

// cors http:www.xxxx 
// axios.defaults.baseURL = '/mall' // /api  https://mock.mengxuegu.com/mock/6142a6b121805563ee103374/mall
// axios.defaults.timeout = 8000
// 拦截器
// instance.interceptors.request.use(
//   config => {
//       // 登录流程控制中，根据本地是否存在token判断用户的登录情况
//       // 但是即使token存在，也有可能token是过期的，所以在每次的请求头 
//       中携带token
//       // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态 
//           码
//       // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
//       const token = store.state.token;
//       token && (config.headers.Authorization = token);
//       return config;
//   },
//   error => Promise.error(error)
// )
// 根据后端返回的状态码拦截
// axios.interceptors.response.use(function(response) {
// 	let res = response.data;
// 	if (res.status == 0) {
// 		return res.data
// 	} else if (res.status == 10) {
// 		// 未登录
// 		window.location.href = '/#/login'
// 	} else {
// 		alert(res.msg)
// 	}
// })
Vue.use(ElementUI)
Vue.use(VueAxios, axios)

Vue.config.productionTip = false
//获取公共数据
// store.dispatch('getCommonData');
//读取本地存储
if ($storage.getItem('userInfo')) {
	let userInfo = $storage.getItem('userInfo');
	let token = $storage.getItem('token');

	store.dispatch('saveUserInfo', userInfo);
	store.dispatch('saveUserToken', token);

} else {
	// $storage.clear('userInfo')
	// $storage.clear('token')
}



new Vue({
	store,
	router,
	i18n,
	$,
	render: h => h(App),
}).$mount('#app')
