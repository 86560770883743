<template>
	<div class="msgBox" >
		<div class="box" >
			<div class="base-info">
				<div class="item-box">
					<div class="item">
						<div class="zui-font-sm labelBox">{{ $t('userInfoDetail.label1') }}:</div>
						<div class="zui-font-sm zui-m-l-10">{{ detail29.registerNum }}</div>
					</div>
					<div class="item">
						<div class="zui-font-sm labelBox">{{ $t('userInfoDetail.label2') }}:</div>
						<div class="zui-font-sm zui-m-l-10">{{ detail29.localArea | areaChange }}</div>
					</div>
					<div class="item">
						<div class="zui-font-sm labelBox">{{ $t('userInfoDetail.label3') }}:</div>
						<div class="zui-font-sm zui-m-l-10">{{ detail29.planeType }}</div>
					</div>
				</div>
				<div class="item-box">
					<div class="item">
						<div class="zui-font-sm labelBox">{{ $t('userInfoDetail.label4') }}:</div>
						<div class="zui-font-sm zui-m-l-10">{{detail29.dueTime}}</div>
					</div>
					<div class="item">
						<div class="zui-font-sm labelBox">{{ $t('userInfoDetail.label5') }}:</div>
						<div class="zui-font-sm zui-m-l-10">{{ detail29.registerArea | areaChange }}</div>
					</div>
					<div class="item">
						<div class="zui-font-sm labelBox">{{ $t('userInfoDetail.label6') }}:</div>
						<div class="zui-font-sm zui-m-l-10">{{ detail29.operationTime }}</div>
					</div>
				</div>
			</div>
			
		
				<div class="title" >{{$t('userInfoDetail.desc1')}}</div>
				<div>
					<el-table :data="table" border style="width: 100%">
						<el-table-column prop="value1" :label="$t('userInfoDetail.th16')"></el-table-column>
						<el-table-column prop="value2" :label="$t('userInfoDetail.th17')"></el-table-column>
						<el-table-column prop="value3" :label="$t('userInfoDetail.th18')"></el-table-column>
						<el-table-column prop="value4" :label="$t('userInfoDetail.th19')"></el-table-column>
					</el-table>
				</div>
		
		</div>
	</div>
</template>

<script>
	import areaObj from '../../util/Area.js';
	import { mapState, mapMutations } from 'vuex';
	export default {
		props: ['detail29','table'],
		data() {
			return {

			}
		},	
		filters: {
		areaChange: function(value) {
			// console.log(value);

			return new areaObj().getText(value);
		}
	},
		computed: {
			...mapState(['userInfo', 'token', 'isLogin'])
		},
		beforeMount: function() {

		},
		methods: {
			...mapMutations(['logOut']),

		}
	}
</script>

<style lang="scss" scoped>
	.msgBox {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		flex:1;
		height: 100%;
		box-sizing: border-box;
		padding-bottom: 20px;
		.box {
			padding-top: 20px;
			width: 98%;
			.title {
				padding: 20px 0;
				font-size: 16px;
				font-weight: bold;
			}
			.base-info {
				display: flex;
				flex-direction: row;
				.item-box {
					flex: 1;
					display: flex;
					flex-direction: column;
				}
				.item {
					flex: 1;
					display: flex;
					flex-direction: row;
					margin-bottom: 10px;
					.labelBox {
						// width: 130px;
						// text-align: right;
					}
				}
			}
		}
	}
	/deep/ th{
		background-color: #C5C2C6 !important;
		.cell {
			color:black !important;
			font-weight: 500;
		}
	}
</style>
