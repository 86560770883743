// 导入axios
let mixin = {
	data() {
		return {
			// 分页 - S
			isMoreLoad: true, // 是否显示加载更多
			loadingImg: false, // 加载更多时显示loading图
			loadLastText: false, // 到底了
			definePageNum: 1, // 默认加载页数
			definePafeSize: 2, // 默认每页数量
			totals: null, // 用来存放总数量
			// 分页 - E
			time: null
		};
	},
	methods: {
		back() {
			this.$router.go(-1); //返回上一层
		},
		//页面跳转
		loadPage(routerName, param = null) {
			// console.log(routerName, param);
			if (param) {
				this.$router.push({
					name: routerName,
					query: param
				});
			} else {
				this.$router.push(routerName);
				// this.$router.push({name:routerName});
			}

			if (this.$store.state.drawer) {
				this.$store.commit('openDrawer', false)
			}
		},
		//滚动监听
		scrollLoadMoreEvn() {
			var _this = this;
			var throttle = function(func, delay) {
				var prev = Date.now();
				return function() {
					var context = this; //this指向window
					var args = arguments;
					var now = Date.now();
					if (now - prev >= delay) {
						func.apply(context, args);
						prev = Date.now();
					}
				}
			}
			function handle() {
				var scr = document.documentElement.scrollTop || document.body.scrollTop; // 向上滚动的那一部分高度
				var clientHeight = document.documentElement.clientHeight; // 屏幕高度也就是当前设备静态下你所看到的视觉高度
				var scrHeight = document.documentElement.scrollHeight || document.body.scrollHeight; // 整个网页的实际高度，兼容Pc端
				if (scr + clientHeight + 50 >= scrHeight) {
					if (_this.isMoreLoad) { //this.isMoreLoad控制滚动是否加载更多
						_this.definePageNum = _this.definePageNum + 1; // 加载更多是definePageNum+1
						_this.scrollLoadMore();
					} else {
						return;
					}
				}
			}
			window.addEventListener('scroll', throttle(handle, 100));
		},
		//飞机库滚动监听
		scrollLoadMoreEvnHangar() {
			var _this = this;
			var throttle = function(func, delay) {
				var prev = Date.now();
				return function() {
					var context = this; //this指向window
					var args = arguments;
					var now = Date.now();
					if (now - prev >= delay) {
						func.apply(context, args);
						prev = Date.now();
					}
				}
			}
			function handle() {
				var scr = document.documentElement.scrollTop || document.body.scrollTop; // 向上滚动的那一部分高度
				var clientHeight = document.documentElement.clientHeight; // 屏幕高度也就是当前设备静态下你所看到的视觉高度
				var scrHeight = document.documentElement.scrollHeight || document.body.scrollHeight; // 整个网页的实际高度，兼容Pc端
				if (scr + clientHeight + 800 >= scrHeight) {
					if (_this.isMoreLoad) { //this.isMoreLoad控制滚动是否加载更多
						_this.definePageNum = _this.definePageNum + 1; // 加载更多是definePageNum+1
						_this.scrollLoadMore2();
					} else {
						return;
					}
				}
			}
			window.addEventListener('scroll', throttle(handle, 100));
		}
	}
};
export default mixin;
