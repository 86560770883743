<template>
	<div class="wrap" >
		<div class="header" ><nav-header></nav-header></div>
		<div class="mainBody" ><router-view></router-view></div>
		<div class="footer" >
			<nav-footer></nav-footer>
		</div>
	</div>
</template>
<script>
import NavHeader from './../components/NavHeader';
import NavFooter from './../components/NavFooter';

export default {
	components: {
		NavHeader,
		NavFooter
	}
};
</script>
<style scoped="scoped" lang="scss">
	.wrap{
		display: flex;
		flex-direction: column;
		height: 100vh;
		width: 100%;
		position: relative;
		
	}
	.header { width: 100%; }
	.footer { height: 140px;width: 100%; }
	.mainBody {
		padding-top: 60px;
		width: 100%;
		flex: 1;
		/* background-color: #EAEAEA !important; */
		position: relative;
		/* background-color: red; */
		
	}
</style>