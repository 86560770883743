export default {
	lang: '中',
	changLang: '切换中文成功',
	//公共
	common: {
		visitors: ' 游客',
		hello: '您好! ',
		personal: '主页',
		logout: '退 出',
		noMore: '暂时无数据',
		back: '返回',
		empty: '暂时无数据',
		submit: '立即提交',
		please: '请输入',
		pleaseSelect: '请选择',
		submitSuccess: '提交成功',
		token: 'Token失效，请重新登录',
		formMsg: {
			account: '不是有效的手机号码或邮箱格式!',
			contactName: '联系人不能为空!',
			contactPhone: '手机号码格式不对!',
			airRange: '飞机航程不能为空!',
			seatNum: '座位数不能为空!',
			planeAge: '机龄不能为空!',
			email: '邮箱格式不对!',
			planeImg: '附件必须上传!',
			planeBrand: '飞机品牌不能为空!',
			planeType: '飞机型号不能为空!',
			price: '飞机价格不能为空!',
			content: '留言内容不能为空!',
			phone: '账号不能为空!',
			realname: '昵称不能为空!',
			username: '账号不能为空!',
			password: '密码至少6位数!',
			confirePassword: '两次密码不匹配!',
			regSuccess: '注册成功!',
			planeName: '品牌或型号',
			code: '验证码不能为空！',
			mesg:'上传头像图片大小不能超过1 2MB!',
			mesg1:'必填项不能为空'
		},
		moreText: [
			'查看更多',
			'正在加载',
			'没有更多数据了',
		],
	},
	//首页
	index: {
		detail: '了解详情',
		recommend: '推荐机型',
		findAir: '查看飞机库存',
		news: '行业新闻',
		more: '更多',
		find: '查找您的',
		plane: '飞机',
		planeBrand: '品牌',
		planeType: '型  号',
		searchPlane: '查找机库'
	},
	//登录页面
	login: {
		login: '登 录',
		account: '您的账号',
		account1: '邮箱或手机号码',
		password: '密码',
		code: '验证码',
		forgot: '修改/忘记密码',
		remember: '记住密码',
		noReg: '没有账号？前往注册',
		username: '账号不能为空!',
		successMesg:'登录成功'
	},
	//密码重置页
	retrievePassword: {
		please: '请输入',
	
		account1: '邮箱或手机号码',
		code: '验证码',
		sendCode: '发送验证码',
		sendCode1: '重新发送',
		sendCode2: '发送中...',
		mesg:'短信已发送，请注意查收',
		mesg1:'邮箱已发送，请注意查收',
		mesg2:'秒重发',
		mesg3:'再次密码不一致!',
		mesg4:'密码重置成功',
		account: '账号',
		newpwd:'新密码',
		enpwd:'确认密码',
		backlogin:'返回登录',
		reg:'重新注册',
		mesg5:'请输入密码'
		
		
	},
	//注册页面
	registered: {
		register: '注 册',
		account: '您的账号',
		account1: '邮箱或手机号码',
		realname: '您的昵称',
		password: '密码',
		agPassword: '确认密码',
		hasAccount: '已有账号？前往登录',
		sendCode: '发送验证码',
		sendCode1: '重新发送',
		sendCode2: '发送中...',
		code: '验证码',
		mesg1:'短信已发送，请注意查收',
		mesg3:'秒重发',
		mesg2:'邮箱已发送，请注意查收'
	},
	//PC 导航
	list: [{
			name: '公司介绍',
			path: '/companyProfile'
		},
		{
			name: '飞机库存',
			path: '/hangar'
		},
		{
			name: '品牌机型',
			path: '/aircraftBrand'
		},
		{
			name: 'LOGO',
			path: '/index'
		},
		{
			name: '客户服务',
			path: '/customerService'
		},
		{
			name: '行业动态',
			path: '/industryNews'
		},
		{
			name: '行业客户',
			path: '/industryCustomers'
		},
		{
			name: '联系我们',
			path: '/contactUs'
		}
	],

	//手机导航
	list_mobile: [{
		name: 'LOGO',
		path: '/index'
	}],
	//侧边栏

	aside: [{
			name: '公司介绍',
			path: '/companyProfile',
			item: []
		},
		{
			name: '飞机库存',
			path: '/search',
			item: [{
					name: '喷气飞机',
					path: '/search/1'
				},

				{
					name: '螺旋桨飞机',
					path: '/search/2'
				},
				{
					name: '直升飞机',
					path: '/search/3'
				},
				{
					name: '其它飞机',
					path: '/search/4'
				},
			]
		},
		{
			name: '品牌机型',
			path: '/aircraftBrand',
			item: [{
				name: '品牌展示',
				path: '/aircraftBrand'
			}]
		},
		{
			name: '客户服务',
			path: '/customerService',
			item: [{
					name: '交易进展',
					path: '/trading'
				},
				{
					name: '月报信息',
					path: '/report'
				}
			]
		},
		{
			name: '行业动态',
			path: '/industryNews',
			item: []
		},
		{
			name: '行业客户',
			path: '/industryCustomers',
			item: []
		},
		{
			name: '联系我们',
			path: '/contactUs',
			item: [{
					name: '飞机购买',
					path: '/contactUs/1'
				},

				{
					name: '飞机出售',
					path: '/contactUs/2'
				},
				{
					name: '飞机管理',
					path: '/contactUs/3'
				},
				{
					name: '工程服务',
					path: '/contactUs/4'
				},
				{
					name: '航空金融',
					path: '/contactUs/5'
				},{
					name: '加入我们',
					path: '/contactUs/6'
				}
			]
		}
	],

	//公司介绍页面
	companyProfile: {
		leader: '董事介绍',
		serviceArr: [{
				title1: '买',
				title2: '飞机',
				title3: '找到合适的飞机给于了解最适合您个人需求的飞机。我们的销售团队',
				title4: '将指导您完成购买流程和飞机选项，以确保您的收购符合您今天和长期的需求。',
				title5: '了解更多信息',
			},
			{
				title1: '卖',
				title2: '飞机',
				title3: '利用我们与可靠买家的牢固关系、独特的全球市场情报和灵活的选择',
				title4: '销售到我们的库存',
				title5: '了解更多信息',
			}
		],
	},
	//飞机库存页面
	hangar: {
		name: '飞机库',
		on_contrast: '加入对比',
		off_contrast: '取消对比',
		contrast: '对比',
		open_contrast: '开启对比',
		off: '取消',
		search: '搜 索',
		planeBrand: '品牌',
		airRange: '飞机航程',
		price: '意向价格',
		all: '全部',
		contact_title: '找不到合适的飞机？',
		contact_desc: '让我们的销售代表与您取得联系',
		contactName: '联系人',
		contactPhone: '手机号码',
		email: '电子邮箱',
		planeType: '型  号',
		seatNum: '座位数',
		contact_us: '联系我们',
		mesg:'至少选择两项才行',
		// options2
		mesg1:'12座以下',
		mesg2:'12-16座',
		mesg3:'16座以上',
		// options3
		mesg4:'5000千米以下',
		mesg5:'5000-8000千米',
		mesg6:'8000-11000千米',
		mesg7:'11000千米以上',
		// options4
		mesg8:'$ 1000万以下',
		mesg9:'$ 1000-5000万',
		mesg10:'$ 5000万以上'
	},
	//品牌机型页面
	aircraftBrand: {
		name: '品牌馆',
		desc: '合作伙伴',
		mseg:'全部品牌'
	},
	//客户服务页面
	customerService: {
		tab1: '交易进展',
		tab2: '月报信息',
		name: '客户服务',
		mesg:'您目前没有访问该功能权限'
	},
	//飞机对比页
	comparison:{
		title:'飞机对比',
		mesg1:'机型',
		mesg2:'发动机',
		mesg3:'APU',
		mesg4:'最大航程',
		mesg5:'巡航高度',
		mesg6:'最大速度',
		mesg7:'座位数',
		mesg8:'最大起飞重量',
		mesg9:'客舱高度',
		mesg10:'客舱长度',
		mesg11:'翼展',
		mesg12:'飞机高度',
	},
	//行业动态页面
	industryNews: {
		name: '行业动态',
		sendDate: '发布日期',
		moreText: [
			'查看更多',
			'正在加载',
			'没有更多数据了',
		],
	},
	//联系我们页面
	contactUs: {
		name: '联系我们',
		title: '尊敬的客户',
		title2:"招贤纳士",
		desc: '如果您对我们的产品、服务、网站有任何意见和建议请及时告诉我们，我们将尽快处理并给您满意的答复',
		planeName: ['意向飞机', '出售飞机', '飞机型号', '飞机型号', '飞机型号'],
		contactName: '您的姓名',
		contactPhone: '您的电话',
		email: '您的邮件',
		content: '留言内容',
		team: '专业团队',
		airRange: '飞机航程',
		seatNum: '座 位 数',
		planeAge: '飞机机龄',
		planeImg: '上传附件',
		uploadResume:"上传简历",
		planeType: '型  号',
		price: '意向价格',
		desc1: '客户需求',
		please: '请输入',
		desc11: '飞行员，航线申请，地面保障，维修支援，报关，物流及账单审核等',
		buyPlane: '飞机购买',
		sellPlane: '飞机出售',
		trusteeshipPlane: '飞机管理',
		trusteeshipPlane1: '工程服务',
		trusteeshipPlane2: '航空金融',
		join:'加入我们',
		label1: '退租管理',
		label2: '改装设计',
		label3: '飞机年检',
		label4: '融资租赁',
		label5: '经营租赁',
		label6: '产权共享',
		label7: '航空信托',
		label8: 'P  S  P',
		upload: '点击上传',
		label9: '所属部门',
		label10: '您的优势',
	},

	//交易进展页面
	trading: {
		tab: '交易进展',
		tab3_title1: '买飞机',
		tab3_title2: '卖飞机',
		tab3_title3: '飞机托管',
		mesg1:'当前没有飞机信息,不能提交',
		mesg2:'评注不能为空!',
		mesg3:'流程描述',
		mesg4:'发表的评注',
		mesg5:'您的评注',
		mesg6:'发送',
		mesg7:'您没有权限查看',
		mesg8:'评注: \n回复: ',
		mesg9:'\n回复: ',
		mesg10:'无'
	},

	//月报信息页面
	report: {
		email: '您的邮件',
		realname: '您的昵称',
		tab2: '月报信息',
		title1: '基本信息',
		title2: '运行月报',
		desc1: '机身及发动机',
		desc2: '统计时段',
		desc3: '行程概要',
		desc4: '当月维修工作',
		desc5: 'AD/SB完成情况',
		desc6: '保留缺陷监控',
		desc7: '结构损伤监控',
		desc8: '其他事项',
		desc9: '账单',
		desc10: '无',
		desc11: '航材管理',
		desc12: ' 发表评注',
		desc13: '您的评注',
		desc14: '回答',
		th1: '序号',
		th2: '日期',
		th3: '维修工作',
		th4: '备注',
		th5: '编号',
		th6: '描述',
		th7: '进展情况',
		th8: '保留级别',
		th9: '故障描述',
		th10: '保留原因',
		th11: '保留日期',
		th12: '应收账款',
		th13: '金额(美元)',
		th14: '付款情况',
		th15: '目前欠款(美元)',
		th16: '项目',
		th17: '型号',
		th18: '总小时数',
		th19: '总循环数',
		th20: '日期',
		th21: '航段',
		th22: '项目',
		th23: '明细',
		th24: '金额(美元)',
		th25: '汇率',
		th26: '金额(人民币)',
		th27: '税率(%)',
		th28: '服务费(人民币)',
		th29: '备注',
		th30: '日期',
		th31: '维修级别',
		th32: '维修项目',
		// 其它
		th33: '序号',
		th34: '项目',
		th35: '日期',
		th36: '内容',
		//结构损伤监控
		th37: '章节号',
		//航材存储
		th38: '序号P/N',
		th39: '序号S/N',
		th40: '库存',
		th41: '有效期',

		th42: '章节号',
		th43: '章节号',
		th44: '开始日期',
		th45: '结束日期',
		th46: '账单序号',
		th47: '单位/数量',
		td1: '机身',
		td2: '发动机1#',
		td3: '发动机2#',
		td4: 'APU',
		label1: '注册号',
		label2: '所在地',
		label3: '机   型',
		label4: '交付日期',
		label5: '注册地',
		label6: '制造日期',
		label7: '飞机总飞行小时数',
		label8: '本月航段数',
		label9: '本月飞机运行小时数',
		// 行程
		label10: ' 起飞地',
		label11: ' 经停地',
		label12: ' 降落地',
		label13: ' 行程',
		label14: ' 小时',
		label15: ' 日期',
		operation: '操作',
		planeImg: '上传附件',
		upload: '点击上传',
		token: 'Token失效，请重新登录',
		mesg1:'月暂无数据',
		mesg2:'修改成功',
		mesg3:'上传头像图片大小不能超过 2MB!',
		mesg4:'当前没有飞机数据，不能提交',
		mesg5:'评注不能为空!',
		mesg6:'附件不能为空!',
		mesg7:'附件或评注至少填写一项!',
		mesg8:'导出PDF',
		mesg9:'正在下载~',
		mesg10:'点击下载 ',
		mesg11:'用户附件：',
		mesg12:'管理附件：',
		mesg13:'请先登录!',
		mesg14: ' 小时 ',
		mesg15: ' 分',
		mesg16:'此操作将永久删除该数据, 是否继续?',
		mesg17: '提示',
		mesg18: '确定',
		mesg19: '取消',
		mesg20:'当前没有飞机信息'
	},
	//行业用户页面
	industryCustomers: {
		name: '行业用户',
		title1: '基本信息',
		title2: '拜访记录',
		token: 'Token失效，请重新登录',
		//行业客户
		time: '时间',
		work: '地点',
		join: '参与人员',
		record: '工作记录',
		addBtn: '上传拜访记录',
		file: '附件上传',
		upload: '点击上传',
		desc: '工作记录照，20M以内',
		//行业客户 - 详情
		label1: '姓名',
		label2: '关联企业',
		label3: '主营行业',
		label4: '城市',
		label5: '是否有公务机',
		label6: '机型',
		label7: '机号',
		label8: '托管公司',
		label9: '满意度',
		label10: '合同到期时间',
		label11: '融资银行',
		label12: '工作阶段',
		label13: '是否近期重点',
		label14: '工作最新进展',
		label15: '小时数',
		label16: '项目负责人',
		label17: '外部关键人',
		label18: '主要对接人',
		label19: '信托类金融产品',
		label20: '便商飞',
		label21: '星保贷',
		label22: '备注',
		mesg:'请先登录!',
		mesg2:'您没有权限查看',
		mesg3:'查询关联企业',
		mesg4:'搜索',
	moreText: [
		'查看更多',
		'正在加载',
		'没有更多数据了',
	],
	},
	//飞机详情页面
	airplaneDetail: {
		label1: '飞行时间',
		label2: '循环次数',
		label3: '交付年份',
		label4: '座位数',
		label5: '飞机高度',
		label6: '翼展长度',
		label7: '客舱长度',
		label8: '客舱高度',
		label9: '最大航程',
		label10: '最大速度',
		label11: '巡航高度',
		label12: '发动机型号',
		label13: '发动机时间',
		label14: 'APU型号',
		label15: 'APU时间',
		label16: '发动机循环',
		label17: 'APU循环',
		label18: '巡航速度',
		title1: '基本参数',
		title2: '发动机',
		title3: '辅助动力（APU）',
		unit1: '小时',
		unit2: '次',
		unit3: '米',
		unit4: '千米/小时',
		unit5: '个',
		unit6: '千米',
		mesg:'文件下载失败',
		mesg1:'正在下载~',
		mesg2:'导出PDF'
	},
	//搜索页面
	search: {
		search: '搜 索',
		planeBrand: '品  牌',
		all: '全部',
		seatNum: '座位数',
		airRange: '飞机航程',
		price: '意向价格',
		on_contrast: '加入对比',
		off_contrast: '取消对比',
		mesg:'至少选择两项才行',
		// options2
		mesg1:'12座以下',
		mesg2:'12-16座',
		mesg3:'16座以上',
		// options3
		mesg4:'5000千米以下',
		mesg5:'5000-8000千米',
		mesg6:'8000-11000千米',
		mesg7:'11000千米以上',
		// options4
		mesg8:'$ 1000万以下',
		mesg9:'$ 1000-5000万',
		mesg10:'$ 5000万以上'
	},
	//用户信息页面
	userinfo: {
		name:'用户信息',
		changeAvatar: '更换头像',
		realname: '您的昵称',
		email: '电子邮箱',
		account: '个人账号',
		mesg:'修改成功',
	},
}
