/* eslint-disable */
//不使用JQuery版的
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import {
	Loading
} from 'element-ui';

/**
 * @param  ele          要生成 pdf 的DOM元素（容器）
 * @param  padfName     PDF文件生成后的文件名字
 * */

function downloadPDF(ele, padfName, child, content, all, all2, btn) {

	let loadingInstance = Loading.service({
		text: '使用Chrome或者edge浏览器,导出效果更佳。正在导出~~'
	});

	btn.style = "display:none"
	// var t = window.devicePixelRatio   // 获取下载的缩放 125% -> 1.25    150% -> 1.5
	// if(t != 1){   // 如果进行了缩放，也就是不是1
	// 	console.log(t)
	//     document.body.style.zoom = -0.6 * t + 1.55;   // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
	// }
	// window.devicePixelRatio = 1.5
	// console.log(1111,ele);
	// ele.style.fontFamily='宋体';
	// ele.style.padding='0 20px';
	// ele.style.transform="scale(0.5)";
	// ele.scrollLeft=299

	let scale = window.devicePixelRatio * 2
	if (child.length > 0) {
		//获取最大长度s
		var list = []
		for (let i = 0; i < child.length; i++) {
			list.push(child[i].scrollWidth)
		}
		var maxwidth = Math.max.apply(Math, list.map(function(o) {
			return o
		}))
	
		content[0].style = "width:" + maxwidth + "px !important;"
		// child[0].scrollLeft=child[0].scrollWidth
		for (let item of all) {
			item.style = "width:" + maxwidth + "px !important;"
			// all[i].offsetWidth=maxwidth
			
		}
		for (let item of all2) {
			item.style = "width:" + maxwidth + "px !important;"

			// all[i].offsetWidth=maxwidth
		}
	
	}
	// console.log(123456,child,document.body.scrollWidth)

	html2canvas(ele, {
		dpi: 300,
		scale: scale,
		useCORS: true, //允许canvas画布内可以跨域请求外部链接图片, 允许跨域请求。
		allowTaint: false,
		height: ele.offsetHeight,
		width: ele.offsetWidth + 40,
		windowWidth: maxwidth ? maxwidth : document.body.scrollWidth,
		windowHeight: document.body.scrollHeight,
	}).then(canvas => {
		this.dialogVisible = true;
		const _this = this;
		//未生成pdf的html页面高度
		var leftHeight = canvas.height;
		var a4Width = 595.28
		var a4Height = 841.89
		//一页pdf显示html页面生成的canvas高度;
		var a4HeightRef = Math.floor(canvas.width / a4Width * a4Height);

		//pdf页面偏移
		var position = 0;

		var pageData = canvas.toDataURL('image/jpeg', 1.0);

		var pdf = new JsPDF('x', 'pt', 'a4');
		var index = 1,
			canvas1 = document.createElement('canvas'),
			height;
		pdf.setDisplayMode('fullwidth', 'continuous', 'FullScreen');

		// $('.head-content').append($(
		//   '<div class="pdfTip">' +
		//   '   <div>正在生成第<span class="pdfProgress">1</span>页，共<span class="pdfTotal"></span>页...' +
		//   '</div>'));
		function createImpl(canvas) {
			if (leftHeight > 0) {
				index++;
				var checkCount = 0;
				if (leftHeight > a4HeightRef) {
					var i = position + a4HeightRef;
					for (i = position + a4HeightRef; i >= position; i--) {
						var isWrite = true
						for (var j = 0; j < canvas.width; j++) {
							var c = canvas.getContext('2d').getImageData(j, i, 1, 1).data

							if (c[0] != 0xff || c[1] != 0xff || c[2] != 0xff) {
								isWrite = false
								break
							}
						}
						if (isWrite) {
							checkCount++
							if (checkCount >= 10) {
								break
							}
						} else {
							checkCount = 0
						}
					}
					height = Math.round(i - position) || Math.min(leftHeight, a4HeightRef);
					if (height <= 0) {
						height = a4HeightRef;
					}
				} else {
					height = leftHeight;
				}

				canvas1.width = canvas.width;
				canvas1.height = height;

				// console.log(index, 'height:', height, 'pos', position);

				var ctx = canvas1.getContext('2d');
				ctx.drawImage(canvas, 0, position, canvas.width, height, 0, 0, canvas.width, height);

				var pageHeight = Math.round(a4Width / canvas.width * height);
				// pdf.setPageSize(null, pageHeight)
				if (position != 0) {
					pdf.addPage();
				}
				pdf.addImage(canvas1.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, a4Width, a4Width / canvas1
					.width * height);
				leftHeight -= height;
				position += height;
				// $('.pdfProgress').text(index + 1);
				// $('.pdfTotal').text(index + Math.ceil(leftHeight / a4HeightRef));
				if (leftHeight > 0) {
					//添加全屏水印
					const base64 = ""
					for (let i = 0; i < 6; i++) {
						for (let j = 0; j < 5; j++) {
							const left = (j * 120) + 20;
							pdf.addImage(base64, 'JPEG', left, i * 150, 20, 30);
						}
					}
					setTimeout(createImpl, 500, canvas);
				} else {
					// _this.iframeSrc = pdf.output('dataurlstring');
					// debugger;
					pdf.save(padfName + '.pdf');
					content[0].style = "width:80%"
					loadingInstance.close();
					// $('.pdfTip').remove();
				}
			}
		}

		//当内容未超过pdf一页显示的范围，无需分页
		if (leftHeight < a4HeightRef) {
			pdf.addImage(pageData, 'JPEG', 10, 10, a4Width, a4Width / canvas.width * leftHeight);
			this.dialogVisible = true;
			// this.iframeSrc = pdf.output('dataurlstring');
			// debugger;
			pdf.save(padfName + '.pdf')
			content[0].style = "width:80%"
			loadingInstance.close();
		} else {
			try {
				pdf.deletePage(0);
				// $('.pdfTip').show();
				// $('.pdfTotal').text(index + Math.ceil(leftHeight / a4HeightRef));
				setTimeout(createImpl, 500, canvas);

			} catch (err) {
				console.log(err);
			}
		}
		// ele.style.padding='0';
		// pdf.addImage(pageData, 'JPEG', 20, position, imgWidth, imgHeight);
	})

}



// function downloadPDF(ele, pdfName){
// 	let loadingInstance = Loading.service(
// 		{text:'正在导出~~'}
// 	);
// 	// Loading.service({
// 	// 	text:'下载中'
// 	// });

//     let eleW = ele.offsetWidth;// 获得该容器的宽
//     let eleH = ele.offsetHeight;// 获得该容器的高
//     let eleOffsetTop = ele.offsetTop;  // 获得该容器到文档顶部的距离
//     let eleOffsetLeft = ele.offsetLeft; // 获得该容器到文档最左的距离

//     var canvas = document.createElement("canvas");
//     var abs = 0;

//     let win_in = document.documentElement.clientWidth || document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
//     let win_out = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）

//     if(win_out>win_in){
//         // abs = (win_o - win_i)/2;    // 获得滚动条长度的一半
//         abs = (win_out - win_in)/2;    // 获得滚动条宽度的一半
//         // console.log(a, '新abs');
//     }
//     canvas.width = eleW * 2;    // 将画布宽&&高放大两倍
//     canvas.height = eleH * 2;

//     var context = canvas.getContext("2d");
//     context.scale(2, 2);
//     context.translate(-eleOffsetLeft -abs, -eleOffsetTop);
//     // 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值，因此
//     // translate的时候，要把这个差值去掉

//     // html2canvas(element).then( (canvas)=>{ //报错
//     // html2canvas(element[0]).then( (canvas)=>{
//     html2canvas( ele, {
//         dpi: 300,
//         // allowTaint: true,  //允许 canvas 污染， allowTaint参数要去掉，否则是无法通过toDataURL导出canvas数据的
//         useCORS:true  //允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。
//     } ).then( (canvas)=>{
//         var contentWidth = canvas.width;
//         var contentHeight = canvas.height;
//         //一页pdf显示html页面生成的canvas高度;
//         var pageHeight = contentWidth / 592.28 * 841.89;
//         //未生成pdf的html页面高度
//         var leftHeight = contentHeight;
//         //页面偏移
//         var position = 30;

//         //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
//         var imgWidth = 595.28 - 40;
//         var imgHeight = (imgWidth /contentWidth * contentHeight) - 40;
//         var pageData = canvas.toDataURL('image/jpeg', 1.0);
//         var pdf = new JsPDF('', 'pt', 'a4');
//         //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
//         //当内容未超过pdf一页显示的范围，无需分页
// 		pdf.addImage(pageData, 'JPEG', 20, position, imgWidth, imgHeight);
//         // if (leftHeight < pageHeight) {
//         //     //在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
//         //     // pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
//         //     pdf.addImage(pageData, 'JPEG', 20, position, imgWidth, imgHeight);
//         // } else {    // 分页
//         //     while(leftHeight > 0) {
//         //         pdf.addImage(pageData, 'JPEG', 20, position, imgWidth, imgHeight);
//         //         leftHeight -= pageHeight ;
//         //         position -= 821.89;
//         //         //避免添加空白页
//         //         if(leftHeight > 400) {
//         //             pdf.addPage();
//         //         }
//         //     }
//         // }
//         //可动态生成
//         pdf.save(pdfName);
// 		loadingInstance.close();

//     })
// }
export default {
	downloadPDF
}
