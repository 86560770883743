<template>
	<div class="messageBox" >
		
		<div class="msg-title">
			<ul>
				<li class="li1" >
					<el-select v-model="value" size="mini" @change="changeSelect" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</li>
				<li class="li2">
					<span :class="[current == index ? 'current title' : 'title']" @click.stop="changeTitle(index)" v-for="(item, index) in title" :key="index">{{ item.name }}</span>
				</li>
			</ul>
		</div>
		<div class="msg-body" >
			
			<ul>
				<li :class="[level >= 1?'current':'']" >
					<span>{{$t('message.tab3_label1')}}</span>
					<span v-if="level >= 1" >{{detail[level - 1].createTime}}</span>
				</li>
				<li  :class="[level >= 1?'current':'']"></li>
				<li :class="[level >= 2?'current':'']">
					<span>{{$t('message.tab3_label2')}}</span>
					<span v-if="level >= 2">{{detail[level - 1].createTime}}</span>
				</li>
				<li :class="[level >= 2?'current':'']"></li>
				<li :class="[level >= 3?'current':'']">
					<span>{{$t('message.tab3_label3')}}</span>
					<span v-if="level >= 3">{{detail[level - 1].createTime}}</span>
				</li >
				<li :class="[level >= 3?'current':'']"></li>
				<li :class="[level >= 4?'current':'']">
					<span>{{$t('message.tab3_label4')}}</span>
					<span v-if="level >= 4">{{detail[level - 1].createTime}}</span>
				</li>
				<li :class="[level >= 4?'current':'']"></li>
				<li :class="[level >= 5?'current':'']">
					<span>{{$t('message.tab3_label5')}}</span>
					<span v-if="level >= 5">{{detail[level - 1].createTime}}</span>
				</li>
			</ul>
		</div>
		<div class="msgFooter" >
			<div v-html="message" style="white-space: pre-wrap;margin:0 20px"></div>
			<el-input type="textarea" :autofocus="true" placeholder="评注" v-model="desc"></el-input>
			<el-button class="jinButton jinBorder btn" @click="addMsg" size="mini" type="primary" >发送</el-button>
		</div>
	</div>
</template>

<script>
	import { mapState, mapMutations } from 'vuex';
	import { getAir,frontProgress,addCommentary } from '../../api/api';
	export default {
		props: {
		},
		data() {
			return {
				desc:'',
				message:'',
				title: [{ name: this.$t('message.tab3_title1') }, { name: this.$t('message.tab3_title2') },{ name: this.$t('message.tab3_title3') }],
				currentId:'',
				current: 0,
				options: [],
				value: '',
				level:0,
				detail:[],
				sellId:0
			}
		},
		computed: {
			...mapState(['userInfo', 'token', 'isLogin'])
		},
		beforeMount: function() {
				this.getData();
		},
		methods: {
			...mapMutations(['logOut']),
			changeSelect(index = 0){
				this.currentId = this.options[index].id
				this.currentIndex = index
				this.getSell(this.currentId);
			},
			changeTitle(index = 0) {
				this.current = index;
				this.level = 0;
				this.detail = [];
				this.getData();
			},
			getData(){
				this.options = [];
				this.value = '';
				this.currentId = 0;
				let queryData = {};
				queryData.userId = this.userInfo.id
				let _this = this;
				let apiArr = ['getAirFindInfo','getAirSellInfo','getAirPlaneTrusteeship'];
				getAir[apiArr[this.current]](queryData).then(res => {
						// console.log(res);
						if (res.code == 200) {
							// console.log(res,8);
							if(res.result.total > 0){
								this.$zui.each(res.result.records, (index, item) => {
									if(index == 0){
										_this.value = item.planeName
										this.currentId = item.id
										_this.getSell(item.id);
									}
									let temp = {};
									temp.label = item.planeName
									temp.value = index
									temp.id = item.id
									_this.options.push(temp)
								});
							}
							
						} else {
							this.$message({
								message: res.message,
								showClose: true,
								type: 'error',
								customClass:'hahaha',
								offset: 20,
								duration: 2000,
							});
							// console.log(res.message);
						}
					});
			},
			getSell(id){
				let queryData = {};
				queryData.type = this.current + 1
				queryData.id = id
			
				frontProgress(queryData).then(res => {
						
						if (res.code == 200) {
						
								this.detail = res.result
								
								let length = res.result.length;
								
								if(length > 1){
									this.level = this.detail[length - 1].level;
								}else{
									this.level = 0;
								}
								this.sellId = this.detail[length - 1].id
								this.message = "评注: " + this.detail[length - 1].commentary + "\n回复: " + (this.detail[length - 1].reply || '无')
						
						} else {
							this.$message({
								message: res.message,
								showClose: true,
								type: 'error',
								customClass:'hahaha',
								offset: 20,
								duration: 2000,
							});
							// console.log(res.message);
						}
					});
			},
			addMsg(){
				let queryData = {};
				queryData.type = this.current + 1
				queryData.id = this.sellId
				queryData.commentary = this.desc
				
				addCommentary(queryData).then(res => {
					// console.log(res);
					if (res.code == 200) {
						this.$message({
							message: this.$t('submitSuccess'),
							showClose: true,
							type: 'success',
							customClass:'hahaha',
							offset: 20,
							duration: 2000,
							onClose: () => {
								this.desc = ''
								this.getSell(this.currentId);
							}
						});
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							offset: 20,
							duration: 2000,
						});
						this.captcha();
						// console.log(res.message);
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.messageBox {
		width: 100%;
		height: 550px;
		flex: 1;
		display: flex;
		flex-direction: column;
		.msg-title {
			height: 50px;
			padding: 15px 20px 0px;
			// background-color: black;
			box-sizing: border-box;
			ul {
				display: flex;
				justify-content: space-between;
				align-items: center;
				list-style: none;
				margin: 0;
				padding: 0;
				height: 100%;
				width: 100%;
				border-bottom: 1px solid rgba($color: #000000, $alpha: 0.6);
				li {
					margin: 0;
					padding: 0;
					// flex: 1;
					height: 100%;
					display: flex;
					align-items: center;
					.title {
						background-color: #f0edf1;
						color: #000000;
						width: 48%;
						height: 100%;
						cursor: pointer;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 14px;
						&.current {
							background-color: #000000;
							color: #f0edf1;
						}
					}
					&.li1 {
						flex-basis: 30% !important;
					}
					&.li2 {
						flex-basis: 60% !important;
					}
					&.li3 {
						display: flex;
						justify-content: flex-end;
					}
				}
			}
		}
		.msg-body {
			flex: 1;
			width: 100%;
			ul {
				width: 100%;
				height: 100%;
				list-style: none;
				padding: 0;
				margin: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				li{
					position: relative;
					background-color: #B1AFB2;
					border-bottom: 2px dashed #B1AFB2;
					&:nth-child(2n+1){
						width: 18px;
						height: 18px;
						border-radius: 100%;
						span {
							position: absolute;
							content: '';
							width: 80px;
							text-align: center;
							left:50%;
							margin-left: -40px;
							font-size: 12px;
							&:first-child{
								top:-30px;
							}
							&:last-child{
								bottom:-30px;
							}
						}
					}
					&:nth-child(2n){
						height: 0;
						width: 15%;
						background-color: transparent !important;
					}
					&.current {
						background-color: #000000;
						&:nth-child(2n){
							border-bottom-color: #000000;
						}
					}
				
				}
			}
		}
		.msgFooter {
			height: 20%;
			background: #F5F5F5;
			border: 1px solid #D7D7D7;
			box-shadow: 0px 8px 7px 1px rgba(0, 0, 0, 0.2);
			padding: 10px;
			box-sizing: border-box;
			position: relative;
			/deep/ .el-textarea {
				height: 100% !important;
				
				.el-textarea__inner {
					background-color: #F5F5F5;
					border: none;
				}
			}
			.btn {
				position: absolute;
				bottom: 5%;
				right:2%;
				height: 30px;
				padding: 0;
				margin: 0;
			}
		}
		.top {
			width: 100%;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	// 手机适配
	@media screen and (max-width: 960px) {
		/deep/ .messageBox {
					.msg-title {
						ul {
							li {
								.title{
									font-size: 10px !important;
								}
								&:last-child {
									display: none !important;
								}
							}
						}
					}
		}
	}
</style>
