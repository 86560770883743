
module.exports = {
	error : '',
	check : function (dataBeCheck, rule){
		dataBeCheck = JSON.stringify(dataBeCheck);
		var data = JSON.parse(dataBeCheck);
		var reg = '';
		var minMax = 0;
		for(var i = 0; i < rule.length; i++){
			if (!rule[i].checkType){ return true;}
			if (!rule[i].name){return true;}
			if (!rule[i].errorMsg) {return true;}
			if (!data[rule[i].name] || data[rule[i].name] == '') {this.error = rule[i].errorMsg; return false;}
			// 检查前去除内容的空格及换行
			if(typeof(data[rule[i].name]) == 'string'){data[rule[i].name] = data[rule[i].name].replace(/\s/g,"");}
			switch (rule[i].checkType){
				case 'string':
					reg = new RegExp('^.{' + rule[i].checkRule + '}$');
					if(!reg.test(data[rule[i].name])) {this.error = rule[i].errorMsg; return false;}
				break;
				case 'int':
					var ruleArr = rule[i].checkRule.split(',');
					if(rule.length < 2){
						ruleArr[0] = Number(ruleArr[0]) - 1;
						ruleArr[1] = '';
					}else{
						ruleArr[0] = Number(ruleArr[0]) - 1;
						ruleArr[1] = Number(ruleArr[1]) - 1;
					}
					reg = new RegExp('^(-[1-9]|[1-9])[0-9]{' + ruleArr[0] + ',' + ruleArr[1] + '}$');
					if(!reg.test(data[rule[i].name])) {this.error = rule[i].errorMsg; return false;}
				break;
				case 'between':
					if (!this.isNumber(data[rule[i].name])){
						this.error = rule[i].errorMsg;
						return false;
					}
					minMax = rule[i].checkRule.split(',');
					minMax[0] = Number(minMax[0]);
					minMax[1] = Number(minMax[1]);
					if (data[rule[i].name] > minMax[1] || data[rule[i].name] < minMax[0]) {
						this.error = rule[i].errorMsg;
						return false;
					}
				break;
				case 'betweenD':
					reg = /^-?\d+$/;
					if (!reg.test(data[rule[i].name])) { this.error = rule[i].errorMsg; return false; }
					minMax = rule[i].checkRule.split(',');
					minMax[0] = Number(minMax[0]);
					minMax[1] = Number(minMax[1]);
					if (data[rule[i].name] > minMax[1] || data[rule[i].name] < minMax[0]) {
						this.error = rule[i].errorMsg;
						return false;
					}
				break;
				case 'betweenF': 
					reg = /^-?[0-9][0-9]?.+[0-9]+$/;
					if (!reg.test(data[rule[i].name])){this.error = rule[i].errorMsg; return false;}
					minMax = rule[i].checkRule.split(',');
					minMax[0] = Number(minMax[0]);
					minMax[1] = Number(minMax[1]);
					if (data[rule[i].name] > minMax[1] || data[rule[i].name] < minMax[0]) {
						this.error = rule[i].errorMsg;
						return false;
					}
				break;
				case 'same':
					if (data[rule[i].name] != rule[i].checkRule) { this.error = rule[i].errorMsg; return false;}
				break;
				case 'notsame':
					if (data[rule[i].name] == rule[i].checkRule) { this.error = rule[i].errorMsg; return false; }
				break;
				case 'email':
					reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
					if (!reg.test(data[rule[i].name])) { this.error = rule[i].errorMsg; return false; }
				break;
				case 'phoneno':
					reg = /^1[0-9]{10,10}$/;
					if (!reg.test(data[rule[i].name])) { this.error = rule[i].errorMsg; return false; }
				break;
				case 'zipcode':
					reg = /^[0-9]{6}$/;
					if (!reg.test(data[rule[i].name])) { this.error = rule[i].errorMsg; return false; }
				break;
				case 'reg':
					reg = new RegExp(rule[i].checkRule);
					if (!reg.test(data[rule[i].name])) { this.error = rule[i].errorMsg; return false; }
				break;
				case 'in':
					if(rule[i].checkRule.indexOf(data[rule[i].name]) == -1){
						this.error = rule[i].errorMsg; return false;
					}
				break;
				case 'notnull':
					if(data[rule[i].name] == null || data[rule[i].name].length < 1){this.error = rule[i].errorMsg; return false;}
				break;
				case 'samewith': 
					if(data[rule[i].name] != data[rule[i].checkRule]){this.error = rule[i].errorMsg; return false;}
				break;
				case 'numbers':
					reg = new RegExp('^[0-9]{' + rule[i].checkRule + '}$');
					if (!reg.test(data[rule[i].name])) { this.error = rule[i].errorMsg; return false; }
				break;
				default:
				// console.log('未知类型');
			}
		}
		return true;
	},
	isNumber : function (checkVal){
		checkVal = Number(checkVal);
		if(isNaN(checkVal)){return false;}
		return true;
	}
}