<template>

			<div class="zui-w-100 zui-h-100" style="overflow-x: scroll;" >
				<div class="contentItem" >
					<div class="contentLeft">
						<ul>
							<li @click="changeLi(index)" v-for="(item,index) in listData" :key="index" :class="[current==index?'current':'']">{{item.name}}</li>
						</ul>
					</div>
					<div class="contentRight">
						<div v-if="current == 0" style="margin-bottom: 20px; width: 100%">
							<div class="zui-flex zui-flex-wrap zui-justify-between"  v-for="(item, index) in table32" :key="index">
								<div class="zui-flex zui-m-r-20"  style="font-size: 18px;" >
									<div class="zui-m-r-10">{{ $t('userInfoDetail.label7') }}:</div>
									<div>{{ item.totalHours }}小时</div>
								</div>
								<div class="zui-flex zui-m-r-20"  style="font-size: 18px;" >
									<div class="zui-m-r-10">{{ $t('userInfoDetail.label8') }}:</div>
									<div>{{ item.flightSegment }}次</div>
								</div>
								<div class="zui-flex zui-m-r-20"  style="font-size: 18px;" >
									<div class="zui-m-r-10">{{ $t('userInfoDetail.label9') }}:</div>
									<div>{{ item.runtime }}小时</div>
								</div>
							</div>

						</div>
						
						<div v-if="current == 1" style="margin-bottom: 20px; width: 100%">
							<div v-for="(item, index) in table33" :key="index" class="zui-flex zui-m-t-20"  style="font-size: 18px;" >
								<div class="zui-m-r-10">
									{{ item.dateDay }}{{ $t('userInfoDetail.label15') }}{{ $t('userInfoDetail.label10') }}{{ item.startFly }}{{ $t('userInfoDetail.label11')
									}}{{ item.betwteenFly }}{{ $t('userInfoDetail.label12') }}{{ item.endFly }}
								</div>
								<div class="" >{{ $t('userInfoDetail.label13') }}{{ item.countHours }}{{ $t('userInfoDetail.label14') }}</div>
							</div>
						</div>
						
						<div v-if="current == 2" style="margin-bottom: 20px; width: 100%">
							<el-table :data="table34" border style="width: 100%">
								<el-table-column width="80" align="center" header-align="center" prop="value1" :label="$t('userInfoDetail.th1')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value2" :label="$t('userInfoDetail.th30')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value3" :label="$t('userInfoDetail.th31')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value4" :label="$t('userInfoDetail.th32')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value5" :label="$t('userInfoDetail.th4')"></el-table-column>
								<!-- <el-table-column v-if="list34.length > 0" width="70" align="center" header-align="center" :label="$t('userInfoDetail.operation')">
									<template slot-scope="scope">
										<img @click="edit('list34', scope.$index)" class="imgClass" src="../assets/image/12.png" />
										<img @click="del('list34', scope.$index)" class="imgClass" src="../assets/image/del.png" />
									</template>
								</el-table-column> -->
							</el-table>
						</div>
						
						<div v-if="current == 3" style="margin-bottom: 20px; width: 100%">
							<el-table :data="table35" border style="width: 100%">
								<el-table-column width="80" align="center" header-align="center" prop="value1" :label="$t('userInfoDetail.th1')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value2" :label="$t('userInfoDetail.th5')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value3" :label="$t('userInfoDetail.th6')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value4" :label="$t('userInfoDetail.th7')"></el-table-column>
								<!-- <el-table-column v-if="list35.length > 0" width="70" align="center" header-align="center" :label="$t('userInfoDetail.operation')">
									<template slot-scope="scope">
										<img @click="edit('list35', scope.$index)" class="imgClass" src="../assets/image/12.png" />
										<img @click="del('list35', scope.$index)" class="imgClass" src="../assets/image/del.png" />
									</template>
								</el-table-column> -->
							</el-table>
						</div>
						
						<div v-if="current == 4" style="margin-bottom: 20px; width: 100%">
							<el-table :data="table36" border style="width: 100%">
								<el-table-column width="80" align="center" header-align="center" prop="value1" :label="$t('userInfoDetail.th1')"></el-table-column>
								<el-table-column  width="240" align="center" header-align="center" prop="value2" :label="$t('userInfoDetail.th20')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value3" :label="$t('userInfoDetail.th8')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value4" :label="$t('userInfoDetail.th9')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value5" :label="$t('userInfoDetail.th10')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value6" :label="$t('userInfoDetail.th11')"></el-table-column>
								<!-- <el-table-column v-if="list36.length > 0" width="70" align="center" header-align="center" :label="$t('userInfoDetail.operation')">
									<template slot-scope="scope">
										<img @click="edit('list36', scope.$index)" class="imgClass" src="../assets/image/12.png" />
										<img @click="del('list36', scope.$index)" class="imgClass" src="../assets/image/del.png" />
									</template>
								</el-table-column> -->
							</el-table>
						</div>
						<div v-if="current == 5" style="margin-bottom: 20px; width: 100%">
							<el-table :data="table39" border style="width: 100%">
								<el-table-column width="80" align="center" header-align="center" prop="value1" :label="$t('userInfoDetail.th1')"></el-table-column>
								<el-table-column  width="240" align="center" header-align="center" prop="value2" :label="$t('userInfoDetail.th20')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value3" :label="$t('userInfoDetail.th16')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value4" :label="$t('userInfoDetail.th37')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value4" :label="$t('userInfoDetail.th29')"></el-table-column>
								<!-- <el-table-column v-if="list39.length > 0" width="70" align="center" header-align="center" :label="$t('userInfoDetail.operation')">
									<template slot-scope="scope">
										<img @click="edit('list39', scope.$index)" class="imgClass" src="../assets/image/12.png" />
										<img @click="del('list39', scope.$index)" class="imgClass" src="../assets/image/del.png" />
									</template>
								</el-table-column> -->
							</el-table>
						</div>
						<div v-if="current == 6" style="margin-bottom: 20px; width: 100%">
							<el-table :data="table40" border style="width: 100%">
								<el-table-column width="80" align="center" header-align="center" prop="value1" :label="$t('userInfoDetail.th1')"></el-table-column>
								<el-table-column  width="240" align="center" header-align="center" prop="value2" :label="$t('userInfoDetail.th20')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value3" :label="$t('userInfoDetail.th16')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value4" :label="$t('userInfoDetail.th36')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value5" :label="$t('userInfoDetail.th29')"></el-table-column>
							</el-table>
						</div>
						
						<div v-if="current == 7" style="margin-bottom: 20px; width: 100%">
							<el-table :data="table37" stripe border style="width: 100%">
								<el-table-column width="80" align="center" header-align="center" prop="value1" :label="$t('userInfoDetail.th1')"></el-table-column>
								<el-table-column  width="240" align="center" header-align="center" prop="value2" :label="$t('userInfoDetail.th20')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value3" :label="$t('userInfoDetail.th21')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value4" :label="$t('userInfoDetail.th22')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value5" :label="$t('userInfoDetail.th23')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value6" :label="$t('userInfoDetail.th24')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value7" :label="$t('userInfoDetail.th25')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value8" :label="$t('userInfoDetail.th26')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value9" :label="$t('userInfoDetail.th27')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value10" :label="$t('userInfoDetail.th28')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value11" :label="$t('userInfoDetail.th29')"></el-table-column>
							</el-table>
						</div>
						
						<div v-if="current == 8" style="margin-bottom: 20px; width: 100%">
							<el-table :data="table38" border style="width: 100%">
								<el-table-column width="80" align="center" header-align="center" prop="value1" :label="$t('userInfoDetail.th1')"></el-table-column>
								<el-table-column  width="240" align="center" header-align="center" prop="value6" :label="$t('userInfoDetail.th41')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value2" :label="$t('userInfoDetail.th34')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value3" :label="$t('userInfoDetail.th38')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value4" :label="$t('userInfoDetail.th39')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value5" :label="$t('userInfoDetail.th40')"></el-table-column>
								<el-table-column align="center" header-align="center" prop="value7" :label="$t('userInfoDetail.th4')"></el-table-column>
							</el-table>
						</div>
						<div v-if="current == 9" style="margin-bottom: 20px; width: 100%">
							<div class="zui-flex-row zui-m-t-20" v-for="(item,index) in table41" :key="index">
								<div class="zui-font-sm" >{{item.createTime}}</div>
								<div class="zui-m-x-20 zui-font-sm" > <span>评注：</span><span>{{item.comment}}</span> </div>
								<div class="zui-font-sm" > <span>回复：</span><span>{{item.reply}}</span> </div>
							</div>
						</div>
					</div>
				</div>
			</div>
</template>

<script>
	import { mapState, mapMutations } from 'vuex';
	export default {
		props: [
		'table32',
		'table33',
		'table34',
		'table35',
		'table36',
		'table37',
		'table38',
		'table39',
		'table40',
		'table41',
		],
		data() {
			return {
				listData:[
					{name:this.$t('userInfoDetail.desc2')},
					{name:this.$t('userInfoDetail.desc3')},
					{name:this.$t('userInfoDetail.desc4')},
					{name:this.$t('userInfoDetail.desc5')},
					{name:this.$t('userInfoDetail.desc6')},
					{name:this.$t('userInfoDetail.desc7')},
					{name:this.$t('userInfoDetail.desc8')},
					{name:this.$t('userInfoDetail.desc9')},
					{name:this.$t('userInfoDetail.desc11')},
					{name:this.$t('userInfoDetail.desc12')},
				],
				current:0
			}
		},
		computed: {
			...mapState(['userInfo', 'token', 'isLogin'])
		},
		mounted: function() {
			console.log(this.table41);
		},
		methods: {
			...mapMutations(['logOut']),
			changeLi(e) {
				this.current = e;
			},
		}
	}
</script>

<style lang="scss" scoped>

			.contentItem {
				width: 100%;
				height: 100%;
				display: flex;
				
				box-sizing: border-box;
				overflow: hidden;
				.contentLeft {
					background-color: #F0EDF1;
					border-right: 1px solid #E3E3E3;
					box-shadow: 1px 0 11px 1px #D1CFD2;
					z-index: 6;
					ul {
						width: 140px;
						height: 100%;
						list-style: none;
						margin: 0;
						padding: 0;
						li {
							box-sizing: border-box;
				
							border-bottom: 1px solid #D6CFCA;
							width: 100%;
							height: 50px;
							display: flex;
							justify-content: center;
							align-items: center;
							cursor: pointer;
							font-size: 12px;
							padding: 0 10px;
							&.current {
								background-color: white;
								color: #DBB96E;
							}
						}
					}
				}
				.contentRight {
					flex:1;
					background-color: #FFFFFF;
					display: flex;
					padding: 5px;
					.title {
						margin-bottom: 10px;
						font-size: 12px;
						font-weight: bold;
						color: #000000;
					}
				}
			}
	/deep/ th{
		background-color: #C5C2C6 !important;
		
	}
	/deep/ .cell {
		color:black !important;
		font-size: 12px;
	}
	// 手机适配
	@media screen and (max-width: 960px) {
			.contentItem {
				.contentLeft {
					ul {
						width: 120px;
						li {
							width: 100%;
							height: 30px;
							font-size: 10px;
							padding: 0;
						}
					}
				}
				.contentRight {
					padding: 10px;
					overflow-x: scroll;
					display: flex;
					.title {
						margin-bottom: 10px;
						font-size: 10px;
					}
					
				}
			}
			/deep/ .cell {
				font-size: 10px !important;
			}
	}
</style>
