// Vuex-mutations
// 加载storage
import $storage from '../storage'
export default {
	//保存用户信息
	saveUserInfo(state, username) {
		state.userInfo = username
		state.isLogin = true
		$storage.setItem('userInfo', state.userInfo);
	},
	//保存token
	saveUserToken(state, params) {
		state.token = params
	},
	//侧边栏开关
	openDrawer(state, params) {
		state.drawer = params
	},
	//语言变量贬值
	saveLanguage(state, params) {
		state.language = params
	},
	//退出登录
	logOut(state) {
		state.userInfo = {}
		state.isLogin = false
		// state.token = null
		$storage.clear('userInfo')
		// $storage.clear('token')
	}
}
