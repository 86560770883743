<template>
	<div class="box-shadow">
		<div class="container-fluid  zui-p-0 zui-m-0">
			<!-- >= 中等屏幕 -->
			<div class="visible-md visible-lg row row-no-gutters header header_bg   zui-p-0 zui-m-0">
				<div @click="$store.commit('openDrawer', true)"
					class="col-md-1 col-sm-2 col-xs-3 padding-0 h-100 cursor flex_row header_l">
					<!-- <img class="col_icon" src="../assets/image/05.png" alt="" /> -->
					<img class="col_icon" src="../assets/image/05.svg" alt="" />
				</div>
				<ul class="col-md-10   col-sm-8 col-xs-8 h-100 header_nav flex_row padding-0 maign-0 ">
					<template v-for="(item, index) in list">
						<li v-if="index!=6" class="flex_1 h-100 flex_column" :key="index" @click="goTo(item.path)">
							<template v-if="index == 3">
								<a class="padding-0 cursor flex_1 maign-0 flex_column"><img class="logo_pc"
										src="../assets/image/LOGO.png" alt="" /></a>
							</template>
							<template v-else>
								<a class="padding-0 cursor flex_1 maign-0 flex_column">{{ item.name }}</a>
							</template>
						</li>
					</template>
				</ul>
				<div class="flex_row header_r padding-0 h-100 col-md-1  col-sm-2 col-xs-3">
					<div v-if="isLogin == false" @click="$router.push({ name: 'login' })"
						class="zui-h-100 zui-pointer zui-flex zui-center-center log">{{$t('login.login')}}</div>
					<!-- <div v-else @click="$router.push({ name: 'userinfo' })"
						class="zui-h-100 zui-pointer zui-flex zui-center-center log">{{$t('personal')}}</div> -->
					<div class="language">
						<span :class="[language === 'zh'?'current':'']" @click="handleSetLanguage('zh')">中</span>
						<span style="border:none;width: 4px;"></span>
						<span :class="[language === 'en'?'current':'']" @click="handleSetLanguage('en')">En</span>
					</div>
				</div>
			</div>
			<!-- 小屏幕 -->
			<div class="visible-sm visible-xs row row-no-gutters header header_bg   zui-p-0 zui-m-0">
				<div @click="$store.commit('openDrawer', true)"
					class="col-md-1 col-sm-4 col-xs-3 padding-0 h-100 cursor flex_row header_l">
					<!-- <img class="col_icon" src="../assets/image/05.png" alt="" /> -->
					<img class="col_icon" src="../assets/image/05.svg" alt="" />
					
				</div>
				<ul class="col-md-10  col-sm-4 col-xs-6 h-100 header_nav flex_row padding-0 maign-0 ">
					<li class="flex_1 h-100 flex_column" @click="goTo('/index')">
						<a class="padding-0 flex_1 maign-0 flex_column" href=""><img class="logo_pc"
								src="../assets/image/LOGO.png" alt="" /></a>
					</li>
				</ul>
				<div class="col-md-1 flex_row header_r padding-0 h-100 col-sm-4 col-xs-3">
					<div v-if="isLogin == false" @click="$router.push({ name: 'login' })"
						class="zui-h-100 zui-pointer zui-flex zui-center-center log">{{$t('login.login')}}</div>
					<!-- <div v-else @click="$router.push({ name: 'userinfo' })"
						class="zui-h-100 zui-pointer zui-flex zui-center-center log">{{$t('personal')}}</div> -->
					<div class="language">
						<span :class="[language === 'zh'?'current':'']" @click="handleSetLanguage('zh')">中</span>
						<span style="border:none;width: 8px;"></span>
						<span :class="[language === 'en'?'current':'']" @click="handleSetLanguage('en')">En</span>
					</div>
				</div>
			</div>
		</div>
		<!-- 抽屉 -->
		<el-drawer ref="drawer" title="我是标题" :visible.sync="$store.state.drawer" direction="ltr" class="hide-scroll"
			:with-header="false" custom-class="chouti" style='z-index: 9999;'>
			<div class="drawer drawer_bg w-100 h-100">
				<ul class="drawer-top">
					<li class="cursor flex_row between" @click="goUserInfo">
						<img v-if="isLogin && userInfo.userPhoto" class="drawer_logo zui-rounded-circle"
							:src="userInfo.userPhoto  | userAvatar" alt="" />
						<img v-else class="drawer_logo" style="width: auto;" src="../assets/image/LOGO.png" alt="" />
					</li>
					<!-- <li @click="openMessage" class="flex_1 cursor flex_row"><img class="drawer_msg"
							src="../assets/image/07.png" alt="" /></li> -->
					<!-- <li @click="$store.commit('openDrawer', false)" class="flex_1 cursor flex_row"><img
							class="drawer_open" src="../assets/image/05-1.png" alt="" /></li> -->
							<li @click="$store.commit('openDrawer', false)" class="cursor flex_row between"><img
									class="drawer_open" src="../assets/image/05-1.svg" alt="" /></li>
				</ul>
				<div class="visible-md visible-lg visible-sm ">
					<div class="nav zui-flex-row zui-p-x-10">
						<div class="zui-flex-1 zui-m-b-10">
							<span class="zui-text-right zui-text-white zui-font-xs">{{$t('common.hello')}}</span>
							<span v-if="isLogin"
								class="zui-text-left zui-text-white  zui-font-xs" style="font-variant:normal" >{{userInfo.userName}}</span>
							<span v-else class="zui-text-left zui-text-white  zui-font-xs">{{$t('common.visitors')}}</span>
						</div>
						<div v-if="isLogin" class="zui-flex-row zui-justify-around">
							<span @click="logOut"
								class="zui-text-left zui-text-white zui-font-xs zui-pointer">{{$t('common.logout')}}</span>
						</div>
						<div v-else class="zui-flex-row zui-justify-around">
							<span @click="loadPage('/login')"
								class="zui-text-left zui-text-white zui-font-xs zui-pointer">{{$t('login.login')}}</span>
							<span @click="loadPage('/registered')"
								class="zui-text-left zui-text-white zui-m-l-10 zui-font-xs zui-pointer">{{$t('registered.register')}}</span>
						</div>
					</div>
				</div>
				<div class="visible-xs ">
					<div class="nav zui-flex-column zui-flex-wrap zui-p-x-10">
						<div class="zui-flex-1 zui-m-b-10">
							<span class="zui-text-right zui-text-white zui-font-xs">{{$t('common.hello')}}</span>
							<span v-if="isLogin"
								class="zui-text-left zui-text-white  zui-font-xs">{{ userInfo.userName }}</span>
							<span v-else class="zui-text-left zui-text-white  zui-font-xs">{{$t('common.visitors')}}</span>
						</div>
						<div v-if="isLogin" class="zui-flex-row zui-justify-end">
							<span @click="logOut"
								class="zui-text-left zui-text-white zui-font-xs zui-pointer">{{$t('common.logout')}}</span>
						</div>
						<div v-else class="zui-flex-row zui-justify-between">
							<span @click="loadPage('/login')"
								class="zui-text-left zui-text-white zui-font-xs zui-pointer">{{$t('login.login')}}</span>
							<span @click="loadPage('/registered')"
								class="zui-text-left zui-text-white zui-font-xs zui-pointer">{{$t('registered.register')}}</span>
						</div>
					</div>
				</div>

				<el-menu default-active="0" text-color="#FFFFFF" :router="true" active-text-color="#E9B444"
					background-color="#1C1C1C" class="el-menu-vertical-demo hide-scroll" @select="closeDrawer">
					<div v-for="(item, index) in list_left" :key="index">
						<template v-if="index==5&&isLogin&&isShowReport">
							<el-menu-item :route="item.path" :index="String(index)">{{ item.name }}</el-menu-item>
						</template>
						<template v-if="index!=5">
							<template v-if="item.item.length > 0">
								<el-submenu popper-class="popperclass" :index="String(index)">
									<template slot="title">
										<span>{{ item.name }}</span>
									</template>
									<el-menu-item :route="v.path" v-for="(v, k) in item.item" :key="k"
										:index="index + '-' + k">{{ v.name }}</el-menu-item>
								</el-submenu>
							</template>
							<template v-else>
								<el-menu-item :route="item.path"  :index="String(index)">{{ item.name }}</el-menu-item>
							</template>
						</template>
					</div>
				</el-menu>
			</div>
		</el-drawer>
		<Model v-if="isLogin" ref="mask"></Model>
		<Message v-if="isLogin" ref="message"></Message>
	</div>
</template>
<script>
	import Model from './model';
	import Message from './message/message.vue';
	import $config from "../api/config.js"
	import {
		mapState,
		mapMutations
	} from 'vuex';

	export default {
		name: 'nav-header',
		components: {
			Model,
			Message
		},
		data() {
			return {
				list: [],
				list_left: [],
			};
		},
		filters: {
			userAvatar: function(value) {
				if ((value.search("http") != -1) && (value.search("https") != -1)) {
					return value
				} else {
					return $config.apiUrl + '/' + value
				}
			},
		},
		computed: {
			...mapState(['userInfo', 'isLogin', 'language']),
			isShowReport() {
				if(this.userInfo.userAuthority){
					let isShow = this.userInfo.userAuthority.indexOf("customer")
					return isShow != -1 ? true : false;
				}else{
					return false;
				}
			}
		},
		created: function() {
			// console.log(this.isLogin);
			// console.log(this.userInfo);
		},
		beforeMount: function() {
			this.list = this.$t("list")
			this.list_left = this.$t("aside")
		},
		methods: {
			scrollFunc(evt) {
				evt = evt || window.event;
				if(evt.preventDefault) {
					//Firefox
					evt.preventDefault();
					evt.stopPropagation();
				}else{
					// IE
					evt.cancelBubble=true;
					evt.returnValue = false;
				}
				return false;
			},
			disabledMouseWheel(obj) {
				if(document.addEventListener){
					obj.addEventListener('DOMMouseScroll', this.scrollFunc, false);
				}//W3C
					obj.onmousewheel=this.scrollFunc;//IE/Opera/Chrome
			},
			...mapMutations(['logOut']),
			closeDrawer() {
				// console.log(e);
				this.$refs.drawer.hide()
			},
			openMessage() {
				if (this.isLogin) {
					this.$refs.drawer.hide()
					this.$refs.message.showMask()
					
					
				} else {
					this.$router.push('/login');
				}
			},

			goUserInfo() {
				if (this.isLogin) {
					this.$refs.drawer.hide()
					// this.$refs.mask.showMask()
					this.$router.push('/userinfo');
				} else {
					this.$router.push('/login');
				}
			},
			goTo(path) {
				this.$router.push(path);
			},
			af(e) {
				// console.log(e);
				e.preventDefault();
			},

			handleSetLanguage(lang) {
				// 选择语言
				this.$i18n.locale = lang;
				//导航语言切换
				this.list = this.$t("list")
				this.list_left = this.$t("aside")

				// 保存vuex
				this.$store.dispatch('setLanguage', lang);
				this.$message({
					message: this.$t("changLang"),
					type: 'success',
					customClass:'hahaha',
					duration: 2000,
				});
				// // 触发重新加载事件
				this.$emit('handerevent');
				
				
			}

		}
	};
</script>
<style lang="scss" scoped>
	.hahaha{
		z-index: 3999 !important;
	}
	
	.cursor.flex_row.between{
		// margin-left: 20px;
	}
	.zui-rounded-circle{
		margin-left:20px ;
	}
	.box-shadow {
		box-shadow: 0 0 5px 2px rgba(14, 10, 14, 0.2);
		// z-index: 3;
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 3000;
	}
	
	.col_icon {
		width: 20px;
	}
	.logo_pc {
		width: 120px;
		// height: 90px;
	}

	.log {
		padding-right: 10px;
		// font-size: 12px;
		font-weight: bold;
		color: #dbb96e;
	}

/deep/ .el-submenu__title, /deep/ .el-menu-item , .nav {
	padding: 0 20px !important;
}
	.header {
		height: 60px !important;
	}

	.header_bg {
		background-color: #f2f2f2;
	}

	.drawer_bg {
		background-color: #1c1c1c;
		text-transform: lowercase;
		font-variant: small-caps;
	}

	.popperclass {
		color: red;
	}

	.el-menu {
		border-right-color: #1c1c1c !important;
	}

	.header_l {}

	.header_r {}

	.header_nav>li>a {
		color: black;
		text-decoration: none;
		font-size: 10px;
		text-align: center;
	}

	.drawer-top {
		padding: 0 20px 0 0 !important;
		height: 80px;
		display: flex;
		justify-content: space-between;
		align-content: center;
		list-style: none;
		margin: 0;
		padding: 0;
	}
	.between{
		display: flex;
		justify-content: space-between !important;
	}

	.drawer_msg {
		width: 30px;
	}

	.language {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: auto;

		span {
			border: 1px solid #6d6d6d;
			color: #6d6d6d;
			width: 16px;
			height: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			font-size: 10px;

			&.current {
				border-color: #DBB96E;
				color: #DBB96E;
			}

			&:hover {
				border-color: #DBB96E;
				color: #DBB96E;
			}
		}
	}

	/deep/ .el-drawer {
		width: 20% !important;
		/deep/ .el-drawer__body {
			
			/deep/ .drawer_bg {
				padding: 0 4%;
				overflow-y: auto  !important;
				/*滚动条样式*/
				/deep/ &::-webkit-scrollbar {
					width: 4px !important;
				}
				
				/deep/ &::-webkit-scrollbar-thumb {
					border-radius: 10px !important;
					-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2)  !important;
					background: rgba(0, 0, 0, 0.2)  !important;
				}
				
				/deep/ &::-webkit-scrollbar-track {
					-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2)  !important;
					border-radius: 0  !important;
					background: rgba(0, 0, 0, 0.1)  !important;
				
				}
			}
		}
		

		
	}
	@media only screen and (max-width: 1170px) {
		/deep/ .log {
			padding-right: 2%;
			
		}
	}
	@media only screen and (max-width: 960px) {
		/deep/ .el-drawer {
			width: 40% !important;
		}
		/deep/ .drawer-top{
			padding: 0 24px 0 0 !important;
		}
		/deep/ .header_l {
			img {
				width: 25px;
			}
		}
		.log {
			padding-right: 10px;

		}
		/deep/ .drawer_msg {
			width: 20px;
		}
		/deep/ .drawer li{
			
			overflow: hidden;
		}
		
		/deep/ .el-submenu__title, /deep/ .el-menu-item , .nav {
			padding: 0 20px !important;
		}
	}
	@media screen and (max-width:480px) {
		/deep/ .drawer-top{
			padding: 0 24px 0 10px !important;
		}
	}
</style>
