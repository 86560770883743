/****   request.js   ****/
// 导入axios
import axios from 'axios'
// 使用element-ui Message做消息提醒
import {
	Message
} from 'element-ui';
// import $config from "../api/config.js"
// 导入vuex
// var apiUrl = $config.apiUrl
import store from '../store'
//1. 创建新的axios实例，
const service = axios.create({
	// 公共接口--这里注意后面会讲
	baseURL: process.env.BASE_API,
	// 超时时间 单位是ms，这里设置了1分钟的超时时间
	timeout: 1 * 60 * 1000
})
// 2.请求拦截器
service.interceptors.request.use(config => {
	//发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
	// config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
	// console.log(config.headers);
	// config.headers = {
	// 	// 'Content-Type':'application/x-www-form-urlencoded' //配置请求头
	// 	'Content-Type': 'application/json' //配置请求头
	// }
	config.headers['Content-Type'] = 'application/json'
	//注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
	//  const token = getCookie('名称');//这里取token之前，你肯定需要先拿到token,存一下
	// console.log(store.state.token);
	
	// console.log(config);
	// if(
	// 	[
	// 		apiUrl+'/airfindinfo/airFindInfo/list',
	// 		apiUrl+'/airsellinfo/airSellInfo/list',
	// 		apiUrl+'/airplanetrusteeship/airPlaneTrusteeship/list',
	// 		apiUrl+'/airplanetrusteeship/airPlaneTrusteeship/edit',
	// 		apiUrl+'/airsellinfo/airSellInfo/edit',
	// 		apiUrl+'/airfindinfo/airFindInfo/edit',
	// 		apiUrl+'/aircustomer/airCustomer/queryAirCustomerRecordByMainIdAndFront',
	// 		apiUrl+'/aircustomer/airCustomer/addByRecord',
	// 		apiUrl+'/airfindinfo/airFindInfo/add',
	// 		apiUrl+'/airsellinfo/airSellInfo/add',
	// 		apiUrl+'/airplanetrusteeship/airPlaneTrusteeship/add',
	// 		apiUrl+'/frontProgress/queryProgress',
	// 		apiUrl+'/frontProgress/addCommentary',
	// 		apiUrl+'/msgnotification/msgNotification/getUpdateNotifi',
	// 		apiUrl+'/airhostingoperation/airHostingOperation/frontEdit',
	// 		apiUrl+'/airhostingoperation/airHostingOperation/addByAirOperationComment',
	// 	].includes(config.url)
	// ){
	// 	if(store.state.isLogin){
	// 		config.headers['X-Access-Token'] = store.state.token;
	// 		config.headers['planeId'] = 11;
	// 	}
	// }
	// console.log(config.headers);
	if(store.state.isLogin){
		config.headers['X-Access-Token'] = store.state.token;
	}
	// console.log(config.params);
	// console.log(config.data);
	// console.log(config.params.unUseToken);
	// console.log(config.data.unUseToken);
	// if ( (config.method == 'get') && config.params.unUseToken == undefined) {
	// 	if (store.state.token) {
	// 		// config.params = {'token':token} //如果要求携带在参数中
	// 		config.headers['X-Access-Token'] = store.state.token; //如果要求携带在请求头中
	// 	}
	// }else if( (config.method == 'post') && config.data.unUseToken == undefined){
	// 	if (store.state.token) {
	// 		// config.params = {'token':token} //如果要求携带在参数中
	// 		config.headers['X-Access-Token'] = store.state.token; //如果要求携带在请求头中
	// 	}
	// }
	// if ( (config.method == 'get') && config.params.unUseToken) {
	// 	delete config.params.unUseToken
	// }else if( (config.method == 'post') && config.data.unUseToken){
	// 	delete config.data.unUseToken
	// }
	return config
}, error => {
	Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
	//接收到响应数据并成功后的一些共有的处理，关闭loading等
	// console.log(response);
	return response.data
}, error => {
	// console.log(error);
	/***** 接收到异常响应的处理开始 *****/
	if (error && error.response) {
		// 1.公共错误处理
		// 2.根据响应码具体处理
		switch (error.response.status) {
			case 400:
				error.message = '错误请求'
				break;
			case 401:
				error.message = '未授权，请重新登录'
				break;
			case 403:
				error.message = '拒绝访问'
				break;
			case 404:
				error.message = '请求错误,未找到该资源'
				window.location.href = "/NotFound"
				break;
			case 405:
				error.message = '请求方法未允许'
				break;
			case 408:
				error.message = '请求超时'
				break;
			case 500:
				error.message = '服务器端出错'
				break;
			case 501:
				error.message = '网络未实现'
				break;
			case 502:
				error.message = '网络错误'
				break;
			case 503:
				error.message = '服务不可用'
				break;
			case 504:
				error.message = '网络超时'
				break;
			case 505:
				error.message = 'http版本不支持该请求'
				break;
			default:
				error.message = `连接错误${error.response.status}`
		}
	} else {
		// 超时处理
		if (JSON.stringify(error).includes('timeout')) {
			console.log(Message)
			// Message.error('服务器响应超时，请刷新当前页')
			this.$message({
				message: '服务器响应超时，请刷新当前页',
				showClose: true,
				type: 'error',
				customClass:'hahaha',
				duration: 2000,
				offset: 20
			});
		}
		error.message = '连接服务器失败'
	}

	// Message.error(error.message)
	this.$message({
		message: error.message,
		showClose: true,
		type: 'error',
		customClass:'hahaha',
		duration: 2000,
		offset: 20
	});
	/***** 处理结束 *****/
	//如果不需要错误处理，以上的处理过程都可省略
	return Promise.resolve(error.response)
})
//4.导入文件
export default service
