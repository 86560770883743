export default {
	lang:'En',
	changLang:'language has been translated',
	//public
	//公共
	common:{
		visitors:'VISITORS',
		hello:'HELLO! ',
		personal:'HOME',
		logout:'LOGOUT',
		noMore:'SORRY',
		back:'BACK',
		empty:'SORRY',
		submit:'SUBMIT',
		please:'PLEASE ENTER',
		pleaseSelect: 'PLEASE SELECT',
		submitSuccess:'SUCCESS',
		token:'Token Invalid, please login again',
	formMsg:{
		account: 'Is not a valid phone number or email format!',
		contactName: 'Contact cannot be empty!',
		contactPhone: 'Wrong mobile phone number format!',
		airRange: 'Aircraft range cannot be empty!',
		seatNum: 'Number of seats cannot be empty!',
		planeAge: 'Machine age cannot be blank!',
		email: 'The mailbox format is incorrect!',
		planeImg: 'Attachments must be uploaded!',
		planeBrand: 'Aircraft brand cannot be empty!',
		planeType: 'Aircraft model cannot be empty!',
		price:'Aircraft price cannot be empty!',
		content:'Message content cannot be empty!',
		phone:'Account number cannot be empty!',
		realname:'Nickname cannot be empty!',
		username:'Account number cannot be empty!',
		password:'The password must have at least 6 digits!',
		confirePassword:'The two passwords do not match!',
		regSuccess:'login was successful!',
		planeName:'BRAND or MODEL',
		code:'Verification code cannot be empty！',
		mesg:'The size of uploaded avatar image cannot exceed 1 2MB!',
		mesg1:'Required item cannot be blank'
	},
	moreText:[
		'SEE MORE',
		'LOADING',
		'NO MORE DATA',
	],
	},
	//home page
	//首页
	index:{
		detail:'LEARN MORE',
		recommend:'SUGGESTIONS FOR YOU',
		findAir:'SEARCH INVENTORY',
		news:'NEWS',
		more:'MORE',
		find:'FIND YOUR',
		plane:'AIRCRAFT',
		planeBrand: 'BRAND',
		planeType: 'MODEL',
		searchPlane:'SEARCH INVENTORY'
	},
	//Login page
	//登录页面
	login:{
		login:'LOGIN',
		account:'ACCOUNT',
		account1:'EMAIL OR PHONE NUMBER',
		password:'PASSWORD',
		code:'Verification Code',
		forgot:'REVISE/FORGET PASSWORD',
		remember:'REMEMBER PASSWORD',
		noReg:'NO ACCOUNT? GO TO REGISTER',
		username:'ACCOUNT CANNOT BE EMPTY!',
		successMesg:'SUCCESS'
	},
	//密码重置页
	retrievePassword:{
	please:'PLEASE ENTER',
	account1:'Email or mobile number',
	code:'Verification Code',
	sendCode:'Send verification code',
	sendCode1:'Resend',
	sendCode2:'Sending...',
	mesg:'SMS has been sent, please check it',
	mesg1:'The email has been sent, please check it',
	mesg2:'Second retransmission',
	mesg3:'Again, the password is inconsistent!',
	mesg4:'Password reset succeeded',
	account: 'ACCOUNT',
	newpwd:'PASSWORD',
	enpwd:'CONFIRM',
	backlogin:'Return to login',
	reg:'REGISTER',
	mesg5:'Please input a password'
	},
	//注册页面
	registered:{
		register:'REGISTER',
		account:'Your account number',
		account1:'Email or mobile number',
		realname:'Your nickname',
		password:'PASSWORD',
		agPassword:'Confirm password',
		hasAccount:'Existing account? Go to login',
		sendCode:'Send verification code',
		sendCode1:'RESEND',
		sendCode2:'SENDING...',
		code:'Verification Code',
		mesg1:'SMS has been sent, please check it',
		mesg2:'The email has been sent, please check it',
		mesg3:'Second retransmission',
	},
	//PC navigation
	//PC 导航
	list: [
			{
				name: 'INTRODUCTION',
				path: '/companyProfile'
			},
			{
				name: 'INVENTORY',
				path: '/hangar'
			},
			{
				name: 'BRAND',
				path: '/aircraftBrand'
			},
			{
				name: 'LOGO',
				path: '/index'
			},
			{
				name: 'CUSTOMER SERVICE',
				path: '/customerService'
			},
			{
				name: 'INDUSTRY NEWS',
				path: '/industryNews'
			},
			{
				name: 'INDUSTRY CUSTOMERS',
				path: '/industryCustomers'
			},
			{
				name: 'CONTACT US',
				path: '/contactUs'
			}
		],
	//Mobile navigation	
	//手机导航
	list_mobile: [
			{
				name: 'LOGO',
				path: '/index'
			}
		],
	//sidebar
	//侧边栏
	aside: [
		{
			name: 'Company introduction',
			path: '/companyProfile',
			item: []
		},
		{
			name: 'Aircraft Inventory',
			path: '/search',
			item: [
				{
					name: 'PRIVATE JETS',
					path: '/search/1'
				},
				
				{
					name: 'TURBOPROPS',
					path: '/search/2'
				},
				{
					name: 'helicopterS',
					path: '/search/3'
				},
				{
					name: 'Other aircraftS',
					path: '/search/4'
				},
			]
		},
		{
			name: 'BrandS ',
			path: '/aircraftBrand',
			item: [
				{
					name: 'BrandS display',
					path: '/aircraftBrand'
				}
			]
		},
		{
			name: 'CUSTOMER SERVICE',
			path: '/customerService',
			item: [
				{
					name: 'Transaction progress',
					path: '/trading'
				},
				{
					name: 'Monthly report',
					path: '/report'
				}
			]
		},
		{
			name: 'INDUSTRY NEWS',
			path: '/industryNews',
			item: []
		},
		{
			name: 'Industry customers',
			path: '/industryCustomers',
			item: []
		},
		{
			name: 'CONTACT US',
			path: '/contactUs',
			item: [
				{
					name: 'BUY Aircraft',
					path: '/contactUs/1'
				},
				
				{
					name: 'sale Aircraft',
					path: '/contactUs/2'
				},
				{
					name: 'Aircraft Management',
					path: '/contactUs/3'
				},
				{
					name: 'Engineering services',
					path: '/contactUs/4'
				},
				{
					name: 'Aviation Finance',
					path: '/contactUs/5'
				},
			]
		}
	],
	//Company introduction page
	//公司介绍页面
	companyProfile:{
		leader:'INTRODUCTION TO DIRECTORS',
		serviceArr:[
			{
				title1:'buy',
				title2:'aircraft',
				title3:'Use our strong relationship with reliable buyers to find the right aircraft to understand the aircraft that best suits your personal needs. Our sales team',
				title4:'Will guide you through the purchase process and aircraft options to ensure that your acquisition meets your needs today and in the long term.',
				title5:'Learn more',
			},
			{
				title1:'sell',
				title2:'aircraft',
				title3:'Leverage our strong relationships with reliable buyers, unique global market intelligence and flexible choices',
				title4:'Sales to our inventory',
				title5:'Learn more',
			}
		],
	},
	//Aircraft Inventory page
	//飞机库存页面
	hangar:{
		name:'HANGAR',
		on_contrast:'COMPARE',
		off_contrast:'CANCEL',
		contrast:'COMPARE',
		open_contrast:'COMPARE',
		off:'CANCEL',
		search:'SEARCH',
		planeBrand: 'BRAND',
		airRange: 'AIRCRAFT RANGE',
		price: 'INTENDED PRICE',
		all:'ALL',
		contact_title:'No suitable plane was found?',
		contact_desc:'Let our sales representative contact you',
		contactName: 'contacts',
		contactPhone: 'phone number',
		email:'E-mail',
		planeType: 'MODEL',
		seatNum: 'NUMBER OF SEATS',
		contact_us:'contact us',
		mesg:'Select at least two items',
		// options2
		mesg1:'Less than 12 seats',
		mesg2:'12-16 seats',
		mesg3:'More than 16 seats',
		// options3
		mesg4:'Below 5000 km',
		mesg5:'5000-8000 km',
		mesg6:'8000-11000 km',
		mesg7:'Over 11000 km',
		// options4
		mesg8:'Less than $10 million',
		mesg9:'$10-50 million',
		mesg10:'Over $50 million'
	},
	//Brand model page
	//品牌机型页面
	aircraftBrand:{
		name:'BRANDS',
		desc:'PARTNERS',
		mseg:'ALL BRANDS'
	},
	//飞机对比页
	comparison:{
		title:'AIRCRAFT COMPARISON',
		mesg1:'MODEL',
		mesg2:'ENGINE',
		mesg3:'APU',
		mesg4:'MAXIMUM RANGE',
		mesg5:'CRUISE ALTITUDE',
		mesg6:'MAXIMUM SPEED',
		mesg7:'NUMBER OF SEATS',
		mesg8:'MAXIMUM TAKEOFF WEIGHT',
		mesg9:'CABIN ALTITUDE',
		mesg10:'CABIN LENGTH',
		mesg11:'WINGSPAN',
		mesg12:'ALTITUDE OF AIRCRAFT',
	},

	//Customer service page
	//客户服务页面
	customerService:{
		tab1:'Transaction progress',
		tab2:'Monthly report information',
		name:'CUSTOMER SERVICE',
		mesg:'You currently do not have access to this feature'
	},
	//Industry dynamic page
	//行业动态页面
	industryNews:{
		name:'INDUSTRY DYNAMICS',
		sendDate:'Release date',
		moreText:[
			'MORE',
			'LOADING',
			'END',
		],
	},
	//联系我们页面
	contactUs:{
		name:'CONTACT US',
		title:'DEAR CUSTOMER',
		title2:"RECRUIT",
		desc:'If you have any comments and suggestions on our products, services and websites, please let us know in time, and we will deal with them as soon as possible and give you a satisfactory reply',
		planeName: ['Intention aircraft', 'Sale of aircraft', 'Aircraft model','Aircraft model','Aircraft model'],
		contactName: 'Your name',
		contactPhone: 'Your phone',
		email:'Your email',
		content: 'Message content',
		team:'Professional team',
		airRange: 'AIRCRAFT RANGE',
		seatNum: 'Number of seats',
		planeAge: 'Aircraft age',
		planeImg: 'attachments',
		planeType: 'Model',
		price:'INTENDED PRICE',
		desc1: 'customer demand',
		please:'PLEASE ENTER',
		desc11:'Pilot, route application, ground support, maintenance support, customs declaration, logistics and bill review, etc',
		buyPlane:'Aircraft purchase',
		sellPlane:'Aircraft sale',
		trusteeshipPlane:'Aircraft Management',
		trusteeshipPlane1:'Engineering service',
		trusteeshipPlane2:'Aviation Finance',
		label1:'Tenancy management',
		label2:'Modification design',
		label3:'Aircraft annual inspection',
		label4:'Finance lease',
		label5:'Operating lease',
		label6:'Property right sharing',
		label7:'Aviation trust',
		label8:'P  S  P',
		join:'JOIN US',
		uploadResume:"resume",
		upload: 'Click upload',
		label9:'DEPARTMENT',
		label10:'ADVANTAGE'
	},
	//Transaction progress page
	//交易进展页面
	trading:{
			tab:'TRANSACTION PROGRESS',
			tab3_title1:'Buy <br/> Aircraft',
			tab3_title2:'Sell <br/> aircraft',
			tab3_title3:'Aircraft <br/> management',
			mesg1:'There is no aircraft information at present and cannot be submitted',
			mesg2:'Comment cannot be empty!',
			mesg3:'Process description',
			mesg4:'Comment',
			mesg5:'Your comments',
			mesg6:'send',
			mesg7:'You do not have permission to view',
			mesg8:'Commentary: \n reply: ',
			mesg9:'\n reply: ',
			mesg10:'nothing'
		},
	//Monthly report information page
	//月报信息页面
	report:{
			email:'Your email',
			realname:'name',
			tab2:'MONTHLY REPORT',
			title1:'ESSENTIAL INFORMATION',
			title2:'OPERATION REPORT',
			desc1:'FUSELAGE AND ENGINES',
			desc2:'STATISTICAL PERIOD',
			desc3:'TRIP SUMMARY',
			desc4:'MAINTENANCE WORK OF THE CURRENT MONTH',
			desc5:'AD/SB COMPLETION STATUS',
			desc6:'RETENTION DEFECT MONITORING',
			desc7:'STRUCTURAL DAMAGE MONITORING',
			desc8:'OTHER MATTERS',
			desc9:'BILL',
			desc10:'NOTHING',
			desc11:'AVIATION MATERIAL MANAGEMENT',
			desc12:'COMMENTARY',
			desc13:'YOUR COMMENTS',
			desc14:'ANSWER',
			th1:'SERIAL NUMBER',
			th2:'DATE',
			th3:'MAINTENANCE',
			th4:'REMARKS',
			th5:'NUMBER',
			th6:'DESCRIBE',
			th7:'PROGRESS',
			th8:'RETENTION LEVEL',
			th9:'FAULT DESCRIPTION',
			th10:'RETENTION REASON',
			th11:'RETENTION DATE',
			th12:'ACCOUNTS RECEIVABLE',
			th13:'AMOUNT (USD)',
			th14:'PAYMENT STATUS',
			th15:'CURRENT ARREARS (USD)',
			th16:'PROJECT',
			th17:'MODEL',
			th18:'TOTAL HOURS',
			th19:'TOTAL CYCLES',
			th20:'DATE',
			th21:'SEGMENT',
			th22:'PROJECT',
			th23:'DETAILED',
			th24:'AMOUNT (USD)',
			th25:'EXCHANGE RATE',
			th26:'AMOUNT (RMB)',
			th27:'TAX RATE (%)',
			th28:'SERVICE CHARGE (RMB)',
			th29:'REMARKS',
			th30:'DATE',
			th31:'MAINTENANCE LEVEL',
			th32:'MAINTENANCE ITEMS',
			//other
			// 其它
			th33:'SERIAL NUMBER',
			th34:'PROJECT',
			th35:'DATE',
			th36:'CONTENT',
			//Structural damage monitoring
			//结构损伤监控
			th37:'CHAPTER NUMBER',
			//Aviation materials storage
			//航材存储
			th38:'PART NUMBER P/N',
			th39:'Serial NUMBER S/N',
			th40:'STOCK',
			th41:'SHELF LIFE',
			
			th42:'CHAPTER NUMBER',
			th43:'CHAPTER NUMBER',
			th44:'DEPARTURE TIME',
			th45:'ARRIVAL TIME',
			th46: 'ORDER',
			th47: 'UNIT/QUANTITY',
			td1:'FUSELAGE',
			td2:'ENGINE1#',
			td3:'ENGINE2#',
			td4:'APU',
			label1:'REGISTRATION NUMBER',
			label2:'LOCATION',
			label3:'MODEL',
			label4:'ENTRY INTO SERVICE DATE',
			label5:'PLACE OF REGISTRATION',
			label6:'DATE OF MANUFACTURE',
			label7:'TOTAL FLIGHT HOURS OF AIRCRAFT',
			label8:'NUMBER OF SEGMENTS IN THIS MONTH',
			label9:'AIRCRAFT OPERATION HOURS IN THIS MONTH',
			//trip
			// 行程
			label10:' DEPARTURE PLACE',
			label11:' STOP PLACE',
			label12:' ARRIVAL PLACE',
			label13:' TRAVEL (HOURS)',
			label14:' HOUR',
			label15:' DATE',
			operation:'OPERATION',
			planeImg: 'ATTACHMENTS',
			upload:'CLICK UPLOAD',
			token:'Token is invalid, please login again',
			mesg1:'No data for month',
			mesg2:'Modified successfully',
			mesg3:'The size of uploaded avatar image cannot exceed 2MB!',
			mesg4:'There is no aircraft data at present and cannot be submitted',
			mesg5:'Comment cannot be empty!',
			mesg6:'Attachment cannot be empty!',
			mesg7:'Fill in at least one attachment or commentary!',
			mesg8:'EXPORT PDF',
			mesg9:'Downloading~',
			mesg10:'Click DOWNLOAD ',
			mesg11:'USER ACCESSORIES:',
			mesg12:'MANAGEMENT ATTACHMENT:',
			mesg13:'Please login first!',
			mesg14: ' hour ',
			mesg15: ' minute',
			mesg16:'This operation will permanently delete the data. do you want to continue?',
			mesg17: 'TIPS',
			mesg18: 'DETERMINE',
			mesg19: 'CANCEL',
			mesg20:'There is currently no aircraft information'
		},
	//Industry user page
	//行业用户页面
	industryCustomers:{
		name:'INDUSTRY USERS',
		title1:'ESSENTIAL INFORMATION',
		title2:'VISIT RECORD',
		token:'Token is invalid, please login again',
		//Industry customers
		//行业客户
		time:'TIME',
		work:'PLACE',
		join:'PARTICIPANTS',
		record:'WORK RECORD',
		addBtn:'UPLOAD VISIT RECORDS',
		file:'ATTACHMENT UPLOAD',
		upload:'CLICK UPLOAD',
		desc:'Work record photo, within 20m',
		//Industry customers - details
		//行业客户 - 详情
		label1:'FULL NAME',
		label2:'AFFILIATED ENTERPRISES',
		label3:'MAIN INDUSTRY',
		label4:'CITY',
		label5:'Is there a business jet',
		label6:'MODEL',
		label7:'MACHINE NUMBER',
		label8:'CUSTODY COMPANY',
		label9:'SATISFACTION',
		label10:'CONTRACT EXPIRATION TIME',
		label11:'FINANCING BANK',
		label12:'WORKING STAGE',
		label13:'RECENT FOCUS',
		label14:'LATEST PROGRESS',
		label15:'Hours',
		label16:'PROJECT LEADER',
		label17:'EXTERNAL KEY PERSON',
		label18:'MAIN COUNTERPART',
		label19:'TRUST FINANCIAL PRODUCTS',
		label20:'CONVENIENT COMMERCIAL FLIGHT',
		label21:'STAR GUARANTEE LOAN',
		label22:'REMARKS',
		mesg:'Please log in first !',
		mesg2:'You do not have permission to view',
		mesg3:'Query related enterprises',
		mesg4:'SEARCH',
		moreText:[
			'SEE MORE',
			'LOADING',
			'NO MORE DATA',
		],
	},
	//Aircraft details page
	//飞机详情页面
	airplaneDetail:{
		label1:'TOTAL HOURS',
		label2:'TOTAL CYCLES',
		label3:'ENTRY INTO SERVICE DATE',
		label4:'NUMBER OF SEATS',
		label5:'ALTITUDE OF AIRCRAFT',
		label6:'WING SPAN',
		label7:'CABIN LENGTH',
		label8:'CABIN ALTITUDE',
		label9:'MAXIMUM RANGE',
		label10:'MAXIMUM SPEED',
		label11:'CRUISE ALTITUDE',
		label12:'ENGINE MODEL',
		label13:'TOTAL HOURS',
		label14:'APU MODEL',
		label15:'TOTAL HOURS',
		label16:'TOTAL CYCLES',
		label17:'TOTAL CYCLES',
		label18:'CRUISE SPEED',
		title1:'SPECIFICATION',
		title2:'ENGINE',
		title3:'AUXILIARY POWER（APU）',
		unit1:'Hour',
		unit2:'CYCLES',
		unit3:'Meter',
		unit4:'Km / h',
		unit5:'Individual',
		unit6: 'Kilometer',
		mesg:'File download failed',
		mesg1:'Downloading~',
		mesg2:'EXPORT PDF'
	},
	//search page
	//搜索页面
	search:{
		search:'SEARCH',
		planeBrand: 'BRAND',
		all:'WHOLE',
		seatNum: 'NUMBER OF SEATS',
		airRange: 'AIRCRAFT RANGE',
		price:'INTENDED PRICE',
		on_contrast:'ADD CONTRAST',
		off_contrast:'CANCEL COMPARISON',
		mesg:'Select at least two items',
		// options2
		mesg1:'Less than 12 seats',
		mesg2:'12-16 seats',
		mesg3:'More than 16 seats',
		// options3
		mesg4:'Below 5000 km',
		mesg5:'5000-8000 km',
		mesg6:'8000-11000km',
		mesg7:'Over 11000 km',
		// options4
		mesg8:'Less than $10 million',
		mesg9:'$10-50 million',
		mesg10:'Over $50 million'
	},
	//User information page
	//用户信息页面
	userinfo:{
			name:'USER INFORMATION',
			changeAvatar:'Your Profile Photo',
			realname:'YOUR NAME',
			email:'E-mail',
			account:'PERSONAL ACCOUNT',
			mesg:'MODIFIED SUCCESSFULLY',
		}
	
}
