<template>
	<div>
		<div class="mask" v-if="isShow" @click.stop="hideMask">
			<div @click.stop="" class="mask-main zui-flex-column">
				<div @click="hideMask" class="closeMask">X</div>
				<div class="msg-title">
					<ul>
						<template v-for="(item, index) in title"  >
						<li :key="index" v-if="isShowReport && index == 0" :class="[current == index ? 'current' : '']" @click.stop="changeTitle(index)" >
							<span :class="[item.note ? 'note title' : 'title']">{{ item.name }}</span>
						</li>
						<li :key="index" v-else :class="[current == index ? 'current' : '']" @click.stop="changeTitle(index)" >
							<span :class="[item.note ? 'note title' : 'title']">{{ item.name }}</span>
						</li>
						</template>
					</ul>
				</div>
				<div class="msg-body">
					<!-- <Msg1 v-if="current == 0" ></Msg1> -->
					<!-- <Msg2 v-if="current == 0"></Msg2> -->
					<Msg3 v-if="current == 0"></Msg3>
					<Msg4 v-if="current == 1"></Msg4>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import Msg1 from './msg1.vue';
// import Msg2 from './msg2.vue';
import Msg3 from './msg3.vue';
import Msg4 from './msg4.vue';
import { mapState, mapMutations } from 'vuex';
import { getUpdateNotifi } from '../../api/api';
export default {
	components: {
		// Msg1,
		// Msg2,
		Msg3,
		Msg4
	},
	props: {},
	data() {
		return {
			isShow: false,
			title: [
				// {name:'消息记录',note:false},
				// { name: '我提交的信息', note: false },
				{ name: this.$t('message.tab1'), note: false },
				{ name: this.$t('message.tab2'), note: false }
			],
			current: 0
		};
	},
	computed: {
		...mapState(['userInfo', 'token', 'isLogin']),
		isShowReport(){
			let isShow =  this.userInfo.userAuthority.indexOf("customer")
			return isShow != -1 ? true:false;
		}
	},
	created: function() {
		if (!this.isLogin) {
			this.$message({
				message: '请先登录!',
				showClose: true,
				customClass:'hahaha',
				type: 'error',
				offset: 20,
				duration: 2000,
				onClose: () => {
					this.$router.push({
						path: '/login'
					});
				}
			});
			return;
		}
	},
	beforeMount: function() {
		this.getData()
	},
	methods: {
		...mapMutations(['logOut']),
		showMask() {
			this.isShow = true;
		},
		hideMask() {
			this.isShow = false;
		},
		changeTitle(index = 0) {
			this.title[index].note = false;
			this.current = index;
		},
		getData() {
			let queryData = {};
			queryData.userId = this.userInfo.id;

			getUpdateNotifi(queryData).then(res => {
				// console.log(res);
				if (res.code == 200) {
					let detail = res.result;
					if(detail.payProcess > 0){
						this.title[1].note = true;
					}
					if(detail.houstingInfo > 0){
						this.title[2].note = true;
					}
				} else {
					this.$message({
						message: res.message,
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						duration: 2000,
						offset: 20
					});
					// console.log(res.message);
				}
			});
		},
	}
};
</script>

<style lang="scss" scoped>
.mask {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba($color: #000000, $alpha: 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99;

	.mask-main {
		// z-index: 10;
		width: 80%;
		min-height: 80%;
		border-radius: 4px;
		background-color: white;
		// overflow: hidden;
		position: relative;
		.closeMask {
			width: 30px;
			height: 30px;
			position: absolute;
			background-color: white !important;
			// top:0;
			// right:0;
			top: -10px;
			right: -10px;
			font-size: 20px;
			display: flex;
			font-weight: bold;
			justify-content: center;
			align-items: center;
			border-radius: 30px;
			cursor: pointer;
		}
		.msg-title {
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #000000;
			color: white;
			box-shadow: 0px 8px 7px 1px rgba(0, 0, 0, 0.2);
			ul {
				width: 100%;
				height: 100%;
				display: flex;
				flex-wrap: nowrap;
				list-style: none;
				justify-content: space-between;
				align-items: center;
				margin: 0;
				padding: 0;
				li {
					margin: 0;
					padding: 0;
					flex: 1;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					cursor: pointer;

					// border:1px solid #D7D7D7;
					&.current {
						background-color: #dab96b;
						span {
							color: #000000;
						}
					}
					// &:last-child{
					// 	border-width: 0;
					// }
					.title {
						font-size: 23px;
						color: white;
						position: relative;
						&.note::after {
							content: '';
							width: 10px;
							height: 10px;
							border-radius: 100%;
							background-color: red;
							position: absolute;
							right: -10px;
							top: 0;
						}
					}
				}
			}
		}

		.msg-body {
			flex: 1;
		}
	}
}
// 手机适配
@media screen and (max-width: 960px) {
	.mask {
		.mask-main {
			width: 95%;
			.closeMask {
				width: 20px;
				height: 20px;
				top: -5px;
				right: -5px;
				font-size: 16px;
				border-radius: 30px;
			}
			.msg-title {
				height: 40px;
				ul {
					li {
						.title {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}
</style>
