<template>
	<div class="nav_header_pages">
		<div class="center zui-flex-row zui-align-center" style="height: 60%;" >
			<div class="" >
				<img class="logo_pc" src="../assets/image/LOGO.png" />
			</div>
			<div class="flex_column header_right  zui-h-100 zui-p-l-20">
				<!-- <div style="margin-bottom: 10px;" >{{$t('searchText')}}</div> -->
				<div>{{data1.label}}&nbsp;&nbsp;{{data1.value}}</div>
				<div>{{data2.label}}&nbsp;&nbsp;{{data2.value}}</div>
				<div>{{data3.label}}&nbsp;&nbsp;{{data3.value}}</div>
				
			</div>
			<div class="visible-md visible-lg header_right zui-h-100">
				<ul class="alinks" >
					<li v-for="(item,index) in listData" :key="index" ><a target="_blank" :href="item.linksAddress"><img class="alinksImg" :src="$apiUrl +'/'+item.linksImg" />{{item.linksName}}</a></li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getAirComSetting,
		getAirLinks
	} from '../api/api';
	export default {
		name: "nav-footer",
		data() {
			return {
				data1: [],
				data2: [],
				data3: [],
				listData:[]
			};
		},
		created: function() {
			this.getData();
		},
		methods: {
			async getData() {
				await getAirComSetting().then(res => {
					// console.log(res);
					if (res.code == 200) {
						// console.log(res);
						
						let temp1 = {};
						temp1.label = res.result.records[1].settingType
						temp1.value = res.result.records[1].settingContent
						// this.data.push(temp1)
						this.data1 =temp1; 
						let temp2 = {};
						temp2.label = res.result.records[2].settingType
						temp2.value = res.result.records[2].settingContent
						// this.data.push(temp2)
						this.data2 =temp2; 
						let temp3 = {};
						temp3.label = res.result.records[3].settingType
						temp3.value = res.result.records[3].settingContent
						// this.data.push(temp3)
						this.data3 =temp3; 
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							customClass:'hahaha',
							type: 'error',
							duration: 2000,
							offset: 20
						});
					}
				});
				getAirLinks().then(res => {
					// console.log(res);
					if (res.code == 200) {
						// console.log(res);
						this.listData = res.result.records
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
					}
				});
			}
		}
	};
</script>
<style lang="scss">
	@import "../assets/scss/config.scss";
	@import "../assets/scss/mixin.scss";

	.nav_header_pages {
		bottom: 0;
		width: 100%;
		height: 140px;
		background-color: $colorC1;
		color: #ffffff;

		@include flex(center, center) .center {
			img {
				width: 110px;
				// height: 80px;
			}

			.header_right {
				box-sizing: border-box;
				margin-left: 20px;
				color: $colorJ;
				border-left: 1px solid $colorJ;

				div {
					width: 100%;
					text-align: left;
					font-size: 12px;
				}
				.alinks {
					height: 100%;
					list-style: none;
					display: flex;
					flex-wrap: wrap;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					li {
						margin-bottom: 5px;
						padding-left: 20px;
						a {
							width: 100%;
							height: 100%;
							display: inline-block;
							text-decoration: none;
							font-size: 14px;
							color:#DBB96E;
							
							.alinksImg {
								width: 20px;
								height: 20px;
								margin-right: 10px;
							}
						}					
					}

					
				}
			}
			
		}
		
	}
</style>
