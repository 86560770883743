import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'
Vue.use(Vuex)
const state = {
  userInfo: {},// 用户信息
  isLogin:false,
  drawer:false, //侧边导航
  token:null,
  language:'zh',
  webSet:{}
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})